import { Button, Typography, Grid, Container, ThemeProvider, createTheme, Alert } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { freeSpace, defaultItems, emptyStats } from './bingo_data';
import sampleSize from 'lodash/sampleSize';
import firebase from "firebase/app";
import "firebase/auth";
import BingoItem from './bingo_item';
import moment, { Moment } from "moment";

const darkTheme = createTheme({
    palette: {
        mode: 'dark'
    }
})

function useStickyState<T>(key: string, defaultValue: T) {
    const [value, setValue] = React.useState(() => {
        const stickyValue = window.localStorage.getItem(key);
        return stickyValue !== null
            ? JSON.parse(stickyValue)
            : defaultValue;
    });
    React.useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);
    return [value, setValue];
}


const Bingo = () => {
    const backgroundAudio = new Audio('/assets/no_michael_no.mp3');
    const [grid, setGrid] = useState<JSX.Element>();
    const [isGenerated, setIsGenerated] = useState(false);
    const [bingoStats, setBingoStats] = useStickyState<boolean[]>('bingoStats', emptyStats);
    const [items, setItems] = useStickyState<string[]>('bingoItems', []);
    const [lastUpdated, setLastUpdated] = useStickyState<Moment>('lastGenerated', moment());

    const updateStat = (idx: number) => {
        const newStats = bingoStats;
        newStats[idx] = !newStats[idx]
        localStorage.setItem('bingoStats', JSON.stringify(newStats))
    }

    const name = firebase.auth().currentUser?.displayName != null && firebase.auth().currentUser?.displayName !== ""
        ? firebase.auth().currentUser?.displayName
        : firebase.auth().currentUser?.email;

    const generateBingo = () => {
        const newItems = sampleSize(defaultItems, 24)
        newItems.splice(12, 0, freeSpace)

        setItems(newItems)
        setBingoStats(emptyStats)
    }

    const generateClickHandler = () => {
        backgroundAudio.play();
        generateBingo();
        setIsGenerated(true);
        setLastUpdated(moment())
    }

    const generateGrid = useCallback((newItems: string[]) => {
        const elements: JSX.Element[] = [];
        newItems.forEach((el, idx) => {
            elements.push(
                <BingoItem label={el} index={idx} statsUpdater={updateStat} isChecked={bingoStats[idx]} key={'bingo_' + idx + Math.random()} />
            )
        })
        return (
            <Container maxWidth='md'>
                <Grid container columns={10}>
                    {elements}
                    <Grid item xs={10}>
                        <Typography variant='h3'>Name: {name}</Typography>
                    </Grid>
                </Grid>
            </Container>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bingoStats])

    useEffect(() => {
        const existingItems = localStorage.getItem('bingoItems');
        if (!existingItems) return

        const newItems: string[] = JSON.parse(existingItems);
        if (newItems.length === 25) {
            setGrid(generateGrid(newItems))
            setIsGenerated(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setGrid(generateGrid(items))
    }, [generateGrid, items])

    return (
        <ThemeProvider theme={darkTheme}>
            <br />
            <Container maxWidth="md">
                <Grid container>
                    {isGenerated &&
                        <Grid item xs>
                            <img src={'/assets/toto_smash.png'} alt='toto' style={{ width: "70%", maxWidth: '500px' }} />
                        </Grid>}
                    <Grid item xs={isGenerated ? 7 : 12}>
                        <img src={'/assets/bingo_header.png'} alt={"foobar"} style={{ maxWidth: '500px' }} />
                    </Grid>
                    {isGenerated &&
                        <Grid item xs>
                            <img src={'/assets/horner.png'} alt='horner' style={{ width: "68%", maxWidth: '500px' }} />
                        </Grid>}
                </Grid>
            </Container>
            <Button variant='outlined' onClick={generateClickHandler}>Generate Bingo Card</Button><br/><br/>
            {moment().diff(lastUpdated, 'days') >= 5 &&
            <Container maxWidth="xs"><Alert severity='warning'>You generated the current bingo card {moment().diff(lastUpdated, 'days')} days ago. Are you sure you don't want to update it?</Alert><br/></Container>
            }
            {grid}
        </ThemeProvider>
    );
}

export default Bingo;