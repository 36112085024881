import React, { Component } from 'react';
import logo from './images/logo.png';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navigation from './components/navigation';
import * as ROUTES from './constants/routes';
import Home from "./components/pages/home";
import Predict from "./components/pages/predict";
import About from './components/pages/about';
import Signout from "./components/pages/signout";
import Account from './components/pages/account';
import Leaderboard from './components/pages/leaderboard/leaderboard';
import Archive from "./components/pages/archive";
import Rules from "./components/pages/rules/rules";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import * as firebaseui from 'firebaseui';
import ResultsInput from "./components/pages/ResultsInput";
import { versionNumber } from "./constants/values";
import Changelog from "./components/pages/changelog/changelog";
import RaceAdd from "./components/pages/RaceAdd/RaceAdd";
import Bingo from './components/pages/bingo/bingo';


class App extends Component {
    state = {
        isSignedIn: false
    }

    uiConfig = {
        signInFlow: 'popup',
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.GithubAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        credentialHelper: firebaseui.auth.CredentialHelper.NONE
    }

    componentDidMount() {
        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            (user) => this.setState({ isSignedIn: !!user })
        )
    }


    componentWillUnmount() {
        this.unregisterAuthObserver();
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            ...this.state,
            hasError: true
        })

        if (process.env.NODE_ENV === "production") {
            firebase.analytics().logEvent("error", {
                error: error,
                errorInfo: errorInfo
            })
        }
    }

    render() {
        let isProd = (process.env.NODE_ENV === "production")
        if (this.state.hasError && isProd) {
            return (
                <div className="App">
                    <header className="App-header">
                        <img src={logo} className="App-logo" alt="logo" />
                    </header>
                    <Router>
                        <div className={"App-nav"}>
                            <Navigation />
                            <br />
                            <h1>I CRASHED :(</h1>
                            <img src={'../assets/error.png'} alt={"Error"} style={{ height: "25vh" }} />
                            <br />
                            <h3>Something has gone very wrong.<br />A team of one supposedly skilled engineer has been
                                notified.</h3>
                            <h3><a href={window.location.href} style={{ color: "white" }}>Reload application</a></h3>
                        </div>
                    </Router>
                </div>
            )
        }

        if (!this.state.isSignedIn) {
            return (
                <div className={"App"}>
                    <header className="App-header">
                        <img src={logo} className="App-logo" alt="logo" />
                    </header>
                    <br /><br />
                    <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
                </div>
            )
        }
        return (
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                </header>
                <Router>
                    <div className={"App-nav"}>
                        <Navigation />
                        <Routes>
                            <Route exact path={ROUTES.LANDING} element={<Home />} />
                            <Route path={ROUTES.PREDICT} element={<Predict />} />
                            <Route exact path={ROUTES.SIGN_OUT} element={<Signout />} />
                            <Route path={ROUTES.ACCOUNT} element={<Account />} />
                            <Route path={ROUTES.LEADERBOARD} element={<Leaderboard />} />
                            <Route path={ROUTES.ARCHIVE} element={<Archive />} />
                            <Route path={ROUTES.RULES} element={<Rules />} />
                            <Route path={ROUTES.ABOUT} element={<About />} />
                            <Route path={ROUTES.RESULTS_INPUT} element={<ResultsInput />} />
                            <Route path={ROUTES.CHANGELOG} element={<Changelog />} />
                            <Route path={ROUTES.ADD_RACE} element={<RaceAdd />} />
                            <Route path={ROUTES.BINGO} element={<Bingo />} />
                        </Routes>
                    </div>
                </Router>
                <h5 id={"footer"}>Made with <span style={{ fontSize: "x-large" }}>🏎️</span> by Jarod
                    Schneider<br />Build {versionNumber}</h5>
            </div>
        );
    }
}

// firebase.initializeApp(firebaseConfig)

export default App;
