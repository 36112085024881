import React from "react";
import {ScaleLoader} from "react-spinners";
import {css} from "@emotion/core";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/firestore";
import logo from '../../images/logo.png';
import {ALL} from "../../constants/values";
import Select from 'react-select';
import DriversPanel from "../DriversPanel";

const override = css`
    display: block;
    margin: 10 auto;
`;

const db = firebase.firestore();

function makeTheme(theme) {
    return {
        ...theme,
        colors: {
            ...theme.colors,
            neutral0: '#1e1e1e', // background and selected text in dropdown
            neutral20: "#ff484d", // outline when not clicked
            neutral80: '#ffffff', // selected text
            primary: '#ff484d', // selected background and border
            primary25: '#ff2f36', // mouseover non-selected
            primary50: "#ff1c23" // on click
        }
    }
}

const years = [
    { value: 2023, label: '2023'},
    { value: 2022, label: '2022'},
    { value: 2021, label: '2021'}
]

class Archive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            selected: "",
            selectedUser: "",
            users: [],
            races: [],
            raceToPass: {},
            selectedYear: 2023
        }
    }

    componentDidMount() {
        if (this.props?.location?.state != null) {
            this.setState(this.props.location.state)
        }
        this.getRaces()
    }

    render() {
        if (!this.state.loaded) {
            return (
                <div className={"loading"}>
                    <h1>Archive</h1>
                    <br/>
                    <ScaleLoader color={"#ff0000"} css={override} size={15}/>
                    <p>Loading...</p>
                </div>
            );
        } else {
            let filename = ""
            if (this.state.selected !== "") {
                filename = '/assets/circuits/' + this.state.selected.label
                    .toLowerCase().replace(/\u00fc/g, "u").replace(/[^a-zA-Z]/g, "") + ".png"
            }
            return (
                    <div className={"archive"}>
                        <h1>Archive</h1>
                        <div className={"column"}>
                            <Select className={"dropBox"} onChange={this._onYearSelect.bind(this)}
                                    options={years}
                                    placeholder={"Select year..."}
                                    defaultValue={years[0]}
                                    theme={someTheme => makeTheme(someTheme)}/>
                            <br/>
                            <Select className={"dropBox"} onChange={this._onSelect.bind(this)}
                                    options={this.state.races}
                                    placeholder={"Select Race..."}
                                    theme={someTheme => makeTheme(someTheme)} value={this.state.selected}/>
                            <br/>
                            <img id={"circuitMap"}
                                 style={{padding: 5, filter: filename !== "" ? "invert(1)" : "invert(0)" }}
                                 src={filename !== "" ? filename : logo}
                                 alt={'stuff'}/>
                            <br/>
                            <Select className={"dropBox"}
                                    key={this.state.raceToPass.id}
                                    options={this.state.users}
                                    placeholder={"Select User..."}
                                    onChange={this._onUserSelect.bind(this)}
                                    theme={theme => makeTheme(theme)}/>
                        </div>
                        <div className={'row'}>
                            <DriversPanel readOnly={true}
                                            key={this.state.raceToPass.id + this.state.selectedUser}
                                            race={this.state.raceToPass}
                                            user={this.state.selectedUser}
                                            component={ALL}
                                            year={this.state.selectedYear}
                                            className={"driverPanel"}/>
                        </div>
                    </div>
            );
        }
    }

    getUsers() {
        db.collection("races").doc(this.state.selected.value).collection("predictions").get()
            .then(querySnapshot => {

                this.setState({
                    ...this.state,
                    users: []
                }, () => {
                    querySnapshot.forEach(doc => {
                        db.collection("users").doc(doc.id).get().then((userDoc) => {
                            if (userDoc.exists){
                                this.state.users.push({value: doc.id, label: userDoc.data().name});
                            }
                        })
                    })
                })
            })
    }

    getRaces() {
        let races = []
        db.collection("races").where("year", "==", this.state.selectedYear).orderBy("race_time")
            .get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
                let data = doc.data()
                let race = {
                    value: doc.id,
                    label: data.name,
                    q1: data.q1,
                    q2: data.q2,
                    q3: data.q3,
                    qualOrder: data.qualOrderResults,
                    finishPosition: data.finishPosition,
                    fastestLap: data.fastestLap,
                    dnfs: data.dnfsResults
                }
                races.push(race)
            });

            this.setState({
                ...this.state,
                races,
                loaded: true
            })
        });
    }

    _onSelect(val) {
        let passRace = {
            ...val,
            id: val.value,
            name: val.label
        }
        this.setState({
            ...this.state,
            selected: val,
            raceToPass: passRace,
            selectedUser: ""
        }, () => {
            this.getUsers();
        });
        this.forceUpdate();
    }

    _onUserSelect(val) {
        this.setState({
            ...this.state,
            selectedUser: val.value
        });
        // console.log(this.state.selectedUser);
        // this.forceUpdate();
    }

    _onYearSelect(val) {
        if (val.value === this.state.selectedYear) {
            return
        }
        this.setState({
            ...this.state,
            selectedYear: val.value,
            selected: "",
            selectedUser: "",
            users: [],
            races: [],
            raceToPass: {},
        }, () => {
            this.getRaces();
        })
    }
}

export default Archive;