import React, { Component } from "react";
import firebase from "firebase/app";
import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";
import { isPreview, versionNumber } from "../../constants/values";
import Background from "../background";

class Home extends Component {
    state = {
        isAdmin: false
    }

    componentDidMount() {
        const uid = firebase.auth().currentUser.uid
        const db = firebase.firestore()

        if (!isPreview) {
            db.collection("control").doc("info").get().then((doc) => {
                if (doc.data()['version'] !== versionNumber && process.env.NODE_ENV === "production") {
                    document.location.reload(true); // out of date, hard refresh
                }
            })
        }

        db.collection("users").doc(uid).get().then((doc) => {
            if (doc.exists) {
                const data = doc.data()
                if (data.admin) {
                    this.setState({
                        isAdmin: true
                    })
                }
            }
        })
    }

    render() {
        return (
            <div className={"homePage"}>
                <Background />
                <div className={"content"}>
                    <h2>BoxBoxBox 2023</h2>
                    <h4><Link className={'App-links'} to={ROUTES.CHANGELOG}>What's New</Link></h4>
                </div>
            </div>
        )
    }
}

// const renderer = ({ days, hours, minutes, seconds, completed }) => {
//     if (completed) {
//         return <span>Race time.</span>;
//     } else {
//         return <h2>{days} DAYS<br/>{hours} HOURS<br/>{minutes} MINUTES<br/>{seconds} SECONDS</h2>;
//     }
// };


export default Home;