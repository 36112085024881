import React from 'react';
import {Link} from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import {Button, Menu, MenuItem} from "@material-ui/core";

class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: firebase.auth().currentUser.displayName != null && firebase.auth().currentUser.displayName !== ""
                ? firebase.auth().currentUser.displayName
                : firebase.auth().currentUser.email,
            isAdmin: false,
            anchorEl: null,
            value: 0
        };
    }

    handleClick(event) {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleClose() {
        this.setState({ anchorEl: null })
    }

    componentDidMount() {
        const uid = firebase.auth()?.currentUser?.uid
        if (!uid) {
            return
        }
        const db = firebase.firestore()
        db.collection("users").doc(uid).get().then((doc) => {
            if (doc.exists) {
                const data = doc.data()
                if (data.admin) {
                    this.setState({
                        isAdmin: true
                    })
                }
            }
        })
    }

    render() {
        // const classes = UseStyles();
        return (
            <div className={'navRow'}>
                <Link className={'App-links'} to={ROUTES.LANDING}>Home</Link>
                {this.state.isAdmin ?
                <Button aria-controls={"simple-menu"} aria-haspopup={"true"} onClick={this.handleClick.bind(this)}>Admin Menu</Button> : null }
                <Menu
                    id={"simple-menu"}
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose.bind(this)}
                    >
                    <MenuItem onClick={this.handleClose.bind(this)} component={Link} to={ROUTES.RESULTS_INPUT}>Add Results</MenuItem>
                    <MenuItem onClick={this.handleClose.bind(this)} component={Link} to={ROUTES.ADD_RACE}>Add New Race</MenuItem>
                </Menu>
                {/*<Link className={'App-links'} to={ROUTES.ABOUT}>About</Link>*/}
                <Link className={'App-links'} to={ROUTES.RULES}>Rules</Link>
                <Link className={'App-links'} to={ROUTES.PREDICT}>Predict</Link>
                <Link className={'App-links'} to={ROUTES.ARCHIVE}>Archives</Link>
                <Link className={'App-links'} to={ROUTES.LEADERBOARD}>Leaderboard</Link>
                <Link className={'App-links'} to={ROUTES.BINGO}>NEW: Bingo</Link>
                <Link className={'App-links'} to={ROUTES.ACCOUNT}>User: {this.state.name}</Link>
                <Link className={'App-links'} onClick={this.processSignOut} to={ROUTES.LANDING}>Sign Out</Link>
            </div>
        )
    }

    processSignOut() {
        firebase.auth().signOut().then(() => {
            // success
        })
    }
}

export default Navigation;