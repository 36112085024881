import React, { useEffect, useState } from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { Done } from '@mui/icons-material';
import { freeSpace } from './bingo_data';

interface BingoItemProps {
    label: string,
    index: number,
    statsUpdater: Function,
    isChecked: boolean,
}

const BingoItem = (props: BingoItemProps) => {
    const [localIsChecked, setIsChecked] = useState(false);

    const clickHandler = () => {
        if (props.label === freeSpace) {
            return
        }
        setIsChecked(!localIsChecked)
        props.statsUpdater(props.index)
    }

    useEffect(() => {
        setIsChecked(props.isChecked)
    }, [props.isChecked])

    return (
        <Grid item xs={2} key={'item_' + props.index} sx={{ position: 'relative' }}>
            <Box onClick={clickHandler} sx={{ border: '1px solid white', height: '10vh', p: '5px' }}>
                {localIsChecked &&
                    <Done sx={{ position: 'relative', zIndex: '2', top: '25%', fontSize: '7vh' }} />
                }
                <Typography sx={{ position: 'absolute', left: '5px', right: '5px', margin: 'auto', top: '5px', bottom: 0 }}>{props.label}</Typography>
            </Box>
        </Grid>
    )
}

export default BingoItem;