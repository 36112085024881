import React, { Component } from 'react';
import './rules.css'

class Rules extends Component {

    render() {
        return (
            <div className={'outer'}>
                <div className={'rules'}>
                    <h1>Rules</h1>
                    <i>Adapted from a game by Kate Burgener</i>
                    <h2>When Predictions Open</h2>
                    Predictions open for each race approximately half an hour after the end of the prior race (2.5 hrs after the race's scheduled start time).
                    <br/><br/>
                    <h2>Predict Qualifying</h2>
                    This is where you predict which qualifying session the drivers will finish in. Five cars do not move on to Q2, so put the five drivers who you think this applies to in the Q1 box. The order in which you put the drivers into each category does not matter.
                    <br/><b>Points</b>: 1 point for each correct prediction
                    <br/><b>Deadline</b>: Predictions for this category are closed when the first qualifying session begins
                    <br/><br/>

                    <h2>Predict Qualifying Order</h2>
                    This is where you predict how the top 10 drivers in Q3 will perform. It is your responsibility to determine which of the ten drivers participate in Q3. You can fill this section out before Q2 ends. This section is for predicting Q3 performance, not the starting order of the race. The order of these predictions <b><u>does</u></b> matter.

                    <br/><b>Points</b>: Predicting the fastest qualifying driver correctly is worth 10 points. Correctly predicting the second driver is worth 9 points . . . etc.
                    <br/><br/>
                    <table className="tg">
                        <tr>
                            <td className="tg-0lax">Qualifying pole position</td>
                            <td className="tg-0lax">10 points</td>
                        </tr>
                        <tr>
                            <td className="tg-0lax">Qualifying P2</td>
                            <td className="tg-0lax">9</td>
                        </tr>
                        <tr>
                            <td className="tg-0lax">Qualifying P3</td>
                            <td className="tg-0lax">8</td>
                        </tr>
                        <tr>
                            <td className="tg-0lax">Qualifying P4</td>
                            <td className="tg-0lax">7</td>
                        </tr>
                        <tr>
                            <td className="tg-0lax">Qualifying P5</td>
                            <td className="tg-0lax">6</td>
                        </tr>
                        <tr>
                            <td className="tg-0lax">Qualifying P6</td>
                            <td className="tg-0lax">5</td>
                        </tr>
                        <tr>
                            <td className="tg-0lax">Qualifying P7</td>
                            <td className="tg-0lax">4</td>
                        </tr>
                        <tr>
                            <td className="tg-0lax">Qualifying P8</td>
                            <td className="tg-0lax">3</td>
                        </tr>
                        <tr>
                            <td className="tg-0lax">Qualifying P9</td>
                            <td className="tg-0lax">2</td>
                        </tr>
                        <tr>
                            <td className="tg-0lax">Qualifying P10</td>
                            <td className="tg-0lax">1</td>
                        </tr>
                    </table>
                    <br/><b>Deadline (in theory)</b>: Predictions for this category are closed when the <b>Q3</b> session starts.
                    <br/><b>Deadline (in practice)</b>: Predictions for this category are closed approximately halfway into the <b>Q3</b> session (this will be fine tuned during the season to get as close as possible).
                    <br/><br/>

                    <h2>Predict Race</h2>
                    This is the form where you predict the final finishing order of each Grand Prix.
                    <br/>This is also where you predict the fastest lap driver of the day; fill in the button on whichever driver you predict will drive the fastest lap of the race.
                    <br/><b>Points</b>: Correctly predicting the race winner is worth 25 points, second place is worth 18, etc. (see table below). Predicting the fastest lap correctly is worth 5 points. Predicting which of the drivers finishes before their teammate is worth 3 points.
                    <br/><br/>
                    <table className="tg">
                        <tr>
                            <td className="tg-0lax">Pole Position</td>
                            <td className="tg-0lax">25 points</td>
                        </tr>
                        <tr>
                            <td className="tg-0lax">P2</td>
                            <td className="tg-0lax">18</td>
                        </tr>
                        <tr>
                            <td className="tg-0lax">P3</td>
                            <td className="tg-0lax">15</td>
                        </tr>
                        <tr>
                            <td className="tg-0lax">P4</td>
                            <td className="tg-0lax">12</td>
                        </tr>
                        <tr>
                            <td className="tg-0lax">P5</td>
                            <td className="tg-0lax">10</td>
                        </tr>
                        <tr>
                            <td className="tg-0lax">P6</td>
                            <td className="tg-0lax">8</td>
                        </tr>
                        <tr>
                            <td className="tg-0lax">P7</td>
                            <td className="tg-0lax">6</td>
                        </tr>
                        <tr>
                            <td className="tg-0lax">P8</td>
                            <td className="tg-0lax">4</td>
                        </tr>
                        <tr>
                            <td className="tg-0lax">P9</td>
                            <td className="tg-0lax">2</td>
                        </tr>
                        <tr>
                            <td className="tg-0lax">P10 - P20</td>
                            <td className="tg-0lax">1</td>
                        </tr>
                    </table>
                    <br/><b>Deadline</b>: Predictions for this category are closed when the formation lap begins.
                    <br/><br/>

                    <h2>Predict DNFs</h2>
                    This is where you predict if any drivers do not finish the race. You choose up to three drivers that DNF. The order in which the drivers are put in this category does not matter. Incorrect predictions do not lose points.
                    <br/><b>Points</b>: Each correct prediction is worth 5 points.
                    <br/><b>Deadline</b>: Predictions for this category are closed when the formation lap begins.
                    <br/><br/><br/>
                </div>
            </div>
        );
    }
}

export default Rules;