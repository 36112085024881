import React, { Component } from 'react';
import './changelog.css'

class Changelog extends Component {
    render() {
        return (
            <div className={'outer'}>
                <div className={'changelog'}>
                    <h2>What's New in 2022</h2>
                    <h4>
                        You can now switch the Archive or Leaderboard pages between the 2021 or 2022 season! In addition, there's now a fun race bingo game you can use each weekend!<br/>
                        Your bingo card is saved in your web browser, so you'll need to use the same computer to access your current game. Current games are saved indefinitely (assuming you don't clear your browser data), but you'll be shown a warning if your current card is getting a little old. Once a new card is generated, there's no way to recover your old card.<br/>
                        I've also updated some aspects of the UI. You may or may not notice them, but I like it a lot more. On the technical side, I've begun transitioning the code from JavaScript to TypeScript (no idea how I tolerated JS while initially building this, maybe that's why the code is such a mess...)
                    </h4>
                    <h2>What's New in 2021</h2>
                    <h4>
                        In addition to the obvious change of supporting the new season, I've made a number of changes that help the application scale across races and (more importantly) seasons. In addition, I'm pleased to announce the following new features:
                        <ul>
                            <li>The Leaderboard</li>
                            <dd>Yes, it's finally here and working! And it's not just a boring static table. Scores for each user are calculated when each batch of results are added. For you, that means the leaderboard is always guaranteed to be up-to-date for everyone as new results are added. In addition, the table is fully sortable by race, name, or total (the default) as well as filterable.</dd>
                            <li>Race Management - Adding (Admins Only)</li>
                            <dd>If you are a designated boxboxbox.org admin, you'll notice a new ADMIN MENU in your navigation bar! This menu contains a new option to add races to the system! While this could be automated, as of now I want to keep this project as cheap to run as I can (read: free other than boxboxbox.org), so this feature gives us a user-friendly way of adding races (you should see how I used to have to do it). In addition, the button to input results has been moved to this menu.</dd>
                        </ul>
                    </h4>
                    <h2>What's Next</h2>
                    <h4>
                        Particularly with the addition of the leaderboard, this is the first version of the website I am comfortable labeling as proper v1.0.0 with pretty much all of the main features Kate and I originally planned. However, there are still improvements and new features that can be added. Currently, I plan on adding:
                        <ul>
                            <li>Editing of race details (names/times/etc. without me fiddling around in the database)</li>
                            <dd>To answer the obvious question of "what about schedule changes?"</dd>
                            <li>Viewing of 2020 results</li>
                            <dd>I know, I know, "why'd you get rid of the old results???" The answer (for now) is that certain changes were made to the database structure to better support future seasons. That said, they aren't gone, and I plan on adding a special place to view the old 2020 results Soon™.</dd>
                            <li>More detailed season/race/teams/cars/drivers/etc. info</li>
                            <dd>While this information is freely available online, part of the original vision was to make this website a sort of "hub" of our F1 experience.</dd>
                            <li>TBA</li>
                            <dd>Have a suggestion? Let me know!</dd>
                        </ul>
                    </h4>
                </div>
            </div>
        );
    }
}

export default Changelog;