import React, {Component} from "react";
import DriversPanel from "../DriversPanel";
import {QUALIFYING, QUALORDER, RACE, DNFs, SPRINT_Q} from '../../constants/values';
import Select from "react-select";

const options = [
    {value: QUALIFYING, label: "Qualifying"},
    {value: QUALORDER, label: "Qualifying order"},
    {value: RACE, label: "Race"},
    {value: DNFs, label: "DNFs"}
];

const sprint_options = [
    {value: QUALIFYING, label: "Qualifying"},
    {value: QUALORDER, label: "Qualifying order"},
    {value: RACE, label: "Race"},
    {value: DNFs, label: "DNFs"},
    {value: SPRINT_Q, label: "Sprint Qualifying"}
];

const year = 2023;

function makeTheme(theme) {
    return {
        ...theme,
        colors: {
            ...theme.colors,
            neutral0: '#1e1e1e', // background and selected text in dropdown
            neutral20: "#ff484d", // outline when not clicked
            neutral80: '#ffffff', // selected text
            primary: '#ff484d', // selected background and border
            primary25: '#ff2f36', // mouseover non-selected
            primary50: "#ff1c23" // on click
        }
    }
}

class Predict extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: QUALIFYING,
            hasSprint: false
        }
    }

    updateHasSprint = (enabled) => {
        this.setState({ ...this.state, hasSprint: enabled})
    }

    render() {
        switch (this.state.selected) {
            case QUALIFYING:
                return (
                    <div className={"predict"}>
                        <h1>Predict</h1>
                        <Select className={"dropBox"} onChange={this._onSelect.bind(this)} options={this.state.hasSprint ? sprint_options : options}
                                theme={theme => makeTheme(theme)} value={this.state.hasSprint ? sprint_options[0] : options[0]}/>
                        <br/>
                        <DriversPanel
                            readOnly={false}
                            component={this.state.selected}
                            className={"driverPanel"}
                            year={year}
                            updateHasSprint={this.updateHasSprint}/>
                    </div>
                );
            case QUALORDER:
                return (
                    <div className={"predict"}>
                        <h1>Predict</h1>
                        <Select className={"dropBox"} onChange={this._onSelect.bind(this)} options={this.state.hasSprint ? sprint_options : options}
                                theme={theme => makeTheme(theme)} value={this.state.hasSprint ? sprint_options[1] : options[1]}/>
                        <br/>
                        <DriversPanel
                            readOnly={false}
                            component={this.state.selected}
                            className={"driverPanel"}
                            year={year}
                            updateHasSprint={this.updateHasSprint}/>
                    </div>
                );
            case RACE:
                return (
                    <div className={"predict"}>
                        <h1>Predict</h1>
                        <Select className={"dropBox"} onChange={this._onSelect.bind(this)} options={this.state.hasSprint ? sprint_options : options}
                                theme={theme => makeTheme(theme)} value={this.state.hasSprint ? sprint_options[2] : options[2]}/>
                        <br/>
                        <DriversPanel
                            readOnly={false}
                            component={this.state.selected}
                            className={"driverPanel"}
                            year={year}
                            updateHasSprint={this.updateHasSprint}/>
                    </div>
                );
            case DNFs:
                return (
                    <div className={"predict"}>
                        <h1>Predict</h1>
                        <Select className={"dropBox"} onChange={this._onSelect.bind(this)} options={this.state.hasSprint ? sprint_options : options}
                                theme={theme => makeTheme(theme)} value={this.state.hasSprint ? sprint_options[3] : options[3]}/>
                        <br/>
                        <DriversPanel
                            readOnly={false}
                            component={this.state.selected}
                            className={"driverPanel"}
                            year={year}
                            updateHasSprint={this.updateHasSprint}/>
                    </div>
                );
            case SPRINT_Q:
                return (
                    <div className={"predict"}>
                        <h1>Predict</h1>
                        <Select className={"dropBox"} onChange={this._onSelect.bind(this)} options={this.state.hasSprint ? sprint_options : options}
                                theme={theme => makeTheme(theme)} value={this.state.hasSprint ? sprint_options[4] : options[4]}/>
                        <br/>
                        <DriversPanel
                            readOnly={false}
                            component={this.state.selected}
                            className={"driverPanel"}
                            year={year}
                            updateHasSprint={this.updateHasSprint}/>
                    </div>
                );
            default:
                return (
                    <div className={"predict"}>
                        <h1>Predict</h1>
                        <Select className={"dropBox"} onChange={this._onSelect.bind(this)} options={this.state.hasSprint ? sprint_options : options}
                                theme={theme => makeTheme(theme)} value={this.state.hasSprint ? sprint_options[0] : options[0]}/>
                        <br/>
                        <DriversPanel
                            readOnly={true}
                            component={this.state.selected}
                            className={"driverPanel"}
                            year={year}
                            updateHasSprint={this.updateHasSprint}/>
                    </div>
                );
        }
    };

    _onSelect(val) {
        this.setState({
            ...this.state,
            selected: val.value
        });
        this.forceUpdate();
    }
}

export default Predict;