import React from "react";
// import { Auth } from 'aws-amplify';

// Auth.signOut().then(r => console.log(r));

const Signout = () => (
    <div className={"signout"}>
        <h2>You have been signed out</h2>
    </div>
);

export default Signout;