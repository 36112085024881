const sprint_weekends = [
    'xrFMTCXNvwNuOB7yaLVO',
    'D7DQ5fu9rTudwAqvZMuJ',
    'fuQAOxWQTUvR0DkdNUjj'
]

type Race = {
    dnfs: string[] | null,
    fastestLap: string | null,
    finishPosition: string[] | null,
    id: string,
    label: string,
    name: string,
    q1: string[] | null,
    q2: string[] | null,
    q3: string[] | null,
    qualOrder: string[] | null,
    value: string,
    hasSprint: boolean | null
}

export const hasSprint = (race: Race) => {
    return race.hasSprint ?? sprint_weekends.includes(race.id);
}