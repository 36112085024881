import React from 'react'
import styled from 'styled-components'
import Driver from './driver'
import { Droppable } from 'react-beautiful-dnd'
import { ThemeProvider, createTheme, Paper } from '@mui/material';


const Container = styled.div`
  margin: 8px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
`;
const Title = styled.h3`
  padding: 0.1vh;
`;
const DriverList = styled.div`
  padding: 8px;
  transition: background-color 0.2s ease;
  flex-grow: 1;
  min-height: 5vh;
`;

const darkTheme = createTheme({
    palette: {
        mode: 'dark'
    }
})

export default class Column extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        let bools = []
        let gotLap = false
        if (props.bools) {
            switch (props.column.id) {
                case "q1":
                    bools = props.bools.q1
                    break
                case "q2":
                    bools = props.bools.q2
                    break
                case "q3":
                    bools = props.bools.q3
                    break
                case "qualOrder":
                    bools = props.bools.qualOrder
                    break
                case "finishPosition": // includes fastestLap
                    bools = props.bools.finishPosition
                    gotLap = props.bools.fastestLap
                    break
                case "dnfs":
                    bools = props.bools.dnfs
                    break
                default:
                    break
            }
        }
        this.state = {
            value: null,
            bools: bools,
            gotLap: gotLap
        }
    }

    getRemaining() {
        switch (this.props.column.id) {
            case 'q1':
                return 5 - this.props.drivers.length
            case 'q2':
                return 5 - this.props.drivers.length
            case 'q3':
                return 10 - this.props.drivers.length
            case 'qualOrder':
                return 10 - this.props.drivers.length
            case 'dnfs':
                return 3 - this.props.drivers.length
            default:
                return -1;
        }
    }

    render() {
        return (
            <ThemeProvider theme={darkTheme}>
                <Container id={"selectCol"}>
                    <Paper>
                        <Title style={{ fontSize: "medium" }}>{this.props.column.title + (this.getRemaining() > 0 ? ` (pick ${this.getRemaining()})` : '' )}</Title>
                        <Droppable droppableId={this.props.column.id} isDropDisabled={false}>
                            {(provided, snapshot) => (
                                <DriverList
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {this.props.drivers.map((driver: any, index: number) => (
                                        <Driver showRadio={this.props.column !== undefined ? this.props.column?.id === "finishPosition" : false}
                                            disabled={this.props.disabled}
                                            key={driver?.id} driver={driver}
                                            result={this.state.bools.length > index ? this.state.bools[index] : null}
                                            gotLap={this.state.gotLap}
                                            race={this.props.race}
                                            showNum={this.props.column?.id === "qualOrder" || this.props.column?.id === "finishPosition" || this.props.column?.id === 'sprint_q'}
                                            hulk={this.props.race != null && this.props.race.id === "Fi48FpFE3Lbk33dqhPUd" && driver.id === "perez"}
                                            index={index} />
                                    ))}
                                    {provided.placeholder}
                                </DriverList>
                            )}
                        </Droppable>
                    </Paper>
                </Container>
            </ThemeProvider>
        )
    }
}
