export const QUALIFYING = 0;
export const QUALORDER = 1;
export const RACE = 2;
export const ALL = 3;
export const DNFs = 4;
export const SPRINT_Q = 5;

export const firebaseConfig = {
    apiKey: "AIzaSyB4TyZXQmPQ6f_zLesiyh0xnXL0olpRa4M",
    authDomain: "boxboxbox.org",
    databaseURL: "https://predictf1-41d08.firebaseio.com",
    projectId: "predictf1-41d08",
    storageBucket: "predictf1-41d08.appspot.com",
    messagingSenderId: "715530297020",
    appId: "1:715530297020:web:8c228a021abcea42167ab9",
    measurementId: "G-V0LFP0ES18"
};

export const versionNumber = '1.2023.1'

export const isPreview = false//#ISPREVIEW#

export const teams = {
    '2021': {
        'mercedes': ['hamilton', 'bottas'],
        'ferrari': ['leclerc', 'sainz'],
        'red_bull': ['verstappen', 'perez'],
        'alpine': ['alonso', 'ocon'],
        'haas': ['mazepin', 'schumacher'],
        'aston_martin': ['stroll', 'perez'],
        'alphaTauri': ['gasly', 'tsunoda'],
        'mclaren': ['norris', 'ricciardo'],
        'alfa_romeo': ['raikkonen', 'giovinazzi'],
        'williams': ['russell', 'latifi']
    },
    '2022': {
        'mercedes': ['hamilton', 'russell'],
        'ferrari': ['leclerc', 'sainz'],
        'red_bull': ['verstappen', 'perez'],
        'alpine': ['alonso', 'ocon'],
        'haas': ['magnussen', 'schumacher'],
        'aston_martin': ['stroll', 'vettel'],
        'alphaTauri': ['gasly', 'tsunoda'],
        'mclaren': ['norris', 'ricciardo'],
        'alfa_romeo': ['bottas', 'zhou'],
        'williams': ['albon', 'latifi']
    },
    '2023': {
        'mercedes': ['hamilton', 'russell'],
        'ferrari': ['leclerc', 'sainz'],
        'red_bull': ['verstappen', 'perez'],
        'alpine': ['gasly', 'ocon'],
        'haas': ['magnussen', 'hulkenberg'],
        'aston_martin': ['stroll', 'alonso'],
        'alphaTauri': ['devries', 'tsunoda'],
        'mclaren': ['norris', 'piastri'],
        'alfa_romeo': ['bottas', 'zhou'],
        'williams': ['albon', 'sargeant']
    }
}