import React, {Component} from 'react';
import {Button} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const styles = {
    root: {
        background: '#1c1c1c'
    },
    input: {
        color: 'white'
    }
};

const db = firebase.firestore();

class Account extends Component {
    state = {
        new_name: "",
        loaded: false
    };

    handleChange(event: any) {
        this.setState({...this.state, new_name: event.target.value});
    }

    render() {
        return (
            <div className={"Account"}>
                <br/><br/>
                <h3>Change your display name</h3>
                <form>
                    Name: <input type={"text"} value={this.state.new_name} onChange={this.handleChange.bind(this)} name={"username"}/> <Button onClick={this.changeName.bind(this)}
                                                                               variant={"contained"}>Change</Button>
                </form>
            </div>
        );
    }

    async changeName() {
        if (this.state.new_name !== "" && this.state.new_name !== "SUCCESS") {
            let user = firebase.auth().currentUser

            // let id = user.uid;
            // db.collection("races").doc(id).update({
            //     name: this.state.new_name
            // });

            await db.collection("users").doc(user?.uid).set({
                name: this.state.new_name
            }, { merge: true }).then(res => {
                user?.updateProfile({
                    displayName: this.state.new_name
                }).then(result => this.setState({...this.state, new_name: "SUCCESS"}))
                    .catch(error => this.setState({...this.state, new_name: "FAILED"}))
            }).catch(fail => {
                this.setState({...this.state, new_name: "FAILED"});
            })
        } else {
            console.log("did not fire")
        }
    }
}

export default withStyles(styles)(Account);