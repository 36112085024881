import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { teamColors } from "../../constants/data";
import { calculateRgba } from "react-spinners/helpers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { Paper } from '@mui/material';
import { green, red } from '@mui/material/colors';

function getStyleLine(driver, which) {
    const style = {
        width: "4%",
        height: "120%",
        background: calculateRgba(teamColors[driver.team], 1.0),
        padding: "auto 0",
        marginLeft: "-2%"
    };
    if (which === 0) {
        return {
            ...style,
        }
    }
}

function getNumStyle() {
    return {
        fontSize: "120%",
        opacity: "30%",
        position: 'relative',
        textAlign: "right",
        minWidth: "10%"
    }
}

export default class Driver extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isHulk: props.hulk,
            names: this.getDriverName()
        }
    }

    getDriverName() {
        const race = this.props.race.id
        const driver = this.props.driver.id
        if (race === "Fi48FpFE3Lbk33dqhPUd" && driver === "perez") {
            return ["Nico", "Hülkenberg"]
        } else if (race === "fsS33kPc64xF46Riw2qQ") {
            if (driver === "grosjean") {
                return ["Pietro", "Fittipaldi"]
            } else if (driver === "russell") {
                return ["Jack", "Aitken"]
            } else if (driver === "hamilton") {
                return ["George", "Russell"]
            }
        } else if (race === "kuDBzBDlcsvAZYBptGDj") {
            if (driver === 'grosjean') {
                return ["Pietro", "Fittipaldi"]
            }
        } else if (race === "wzxR3lbOB6FwqNeJby3D" || race === 'F1MZMz1aVlAhYwcS31qN') {
            if (driver === 'vettel') {
                return ['Nico', "Hülkenberg"]
            }
        }

        return [this.props.driver.fname, this.props.driver.content]
    }

    getDriverStyle() {
        const style = {}
        style['marginBottom'] = '8px'
        if (this.props.result) style['backgroundColor'] = green[800]
        return style;
    }

    render() {
        const isDragDisabled = this.props.disabled;
        if (this.props.showRadio) {
            return (
                <div className={"driverContainer"}>
                    <Draggable
                        key={this.props.driver.id}
                        draggableId={this.props.driver.id}
                        index={this.props.index}
                        isDragDisabled={isDragDisabled}>
                        {(provided, snapshot) => (
                            <Paper
                                id={"container"}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                sx={this.getDriverStyle()}
                                elevation={3}
                            >
                                <div className={"driverOuter"}>
                                    <img id={"driverImg"}
                                        src={`/assets/${this.state.names[1].toLowerCase()
                                            }.png`}
                                        alt={this.state.names[1]} />
                                    <div className={"driverLine"} style={getStyleLine(this.props.driver, 0)} />
                                    {this.props.showNum ?
                                        <h1 style={getNumStyle()}>
                                            {this.props.index + 1}
                                        </h1>
                                        : null}
                                    <div id={"driverName"}>
                                        <div id={"fname"}>{
                                            this.state.names[0]
                                        }</div>
                                        <div id={"lname"}>{
                                            this.state.names[1]
                                        }</div>
                                    </div>
                                    {this.props.showRadio &&
                                        <FormControlLabel value={this.props.driver.id || ""}
                                            control={<Radio style={{
                                                color: this.props.gotLap ?
                                                    green['A200']
                                                    : red[800]
                                            }} />}
                                            style={{
                                                top: "-7px",
                                                right: "-23px",
                                                position: "absolute"
                                            }} label={""} />
                                    }
                                </div>
                            </Paper>
                        )}
                    </Draggable>
                </div>
            )
        } else {
            return (
                <div className={"driverContainer"}>
                    <Draggable
                        key={this.props.driver.id}
                        draggableId={this.props.driver.id}
                        index={this.props.index}
                        isDragDisabled={isDragDisabled}>
                        {(provided, snapshot) => (
                            <Paper
                                id={"container"}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                elevation={3}
                                sx={this.getDriverStyle()}
                            >
                                <div className={"driverOuter"}>
                                    <div className={"driverLine"} style={getStyleLine(this.props.driver, 0)} />
                                    {this.props.showNum ?
                                        <h1 style={getNumStyle()}>
                                            {this.props.index + 1}
                                        </h1>
                                        : null}
                                    <div id={"driverName"}>
                                        <div id={"fname"}>{
                                            this.state.names[0]
                                        }</div>
                                        <div id={"lname"}>{
                                            this.state.names[1]
                                        }</div>
                                    </div>
                                    <img id={"driverImg"}
                                        src={`/assets/${this.state.names[1].toLowerCase()
                                            }.png`}
                                        alt={this.state.names[1]} />
                                </div>
                            </Paper>
                        )}
                    </Draggable>
                </div>
            )
        }
    }
}
