const DEFAULT_IDS_1 = ['hamilton', 'russell', 'verstappen', 'perez', 'leclerc', 'sainz', 'norris', "piastri", "ocon", 'gasly']
const DEFAULT_IDS_2 = ['devries', 'tsunoda', 'stroll', 'alonso', 'albon', 'sargeant', 'bottas', 'zhou', 'hulkenberg', 'magnussen']

export const driverData = {
    2021: {
        drivers: {
            'hamilton': { id: 'hamilton', fname: 'Lewis', content: 'Hamilton', team: 'mercedes' },
            'bottas': { id: 'bottas', fname: 'Valtteri', content: 'Bottas', team: 'mercedes' },
            'verstappen': { id: 'verstappen', fname: 'Max', content: "Verstappen", team: 'red_bull' },
            'leclerc': { id: 'leclerc', fname: "Charles", content: 'Leclerc', team: 'ferrari'},
            'vettel': { id: 'vettel', fname: "Sebastian", content: 'Vettel', team: 'aston_martin'},
            'gasly': { id: 'gasly', fname: "Pierre", content: 'Gasly', team: 'alphaTauri'},
            'sainz': { id: 'sainz', fname: "Carlos", content: "Sainz", team: 'ferrari'},
            'schumacher': { id: 'schumacher', fname: "Mick", content: 'Schumacher', team: 'haas'},
            'ricciardo': { id: 'ricciardo', fname: "Daniel", content: 'Ricciardo', team: 'mclaren'},
            'perez': { id: 'perez', fname: "Sergio", content: 'Perez', team: 'red_bull'},
            'norris': { id: 'norris', fname: "Lando", content: 'Norris', team: 'mclaren'},
            'raikkonen': { id: 'raikkonen', fname: "Kimi", content: 'Räikkönen', team: 'alfa_romeo'},
            'ocon': { id: 'ocon', fname: "Esteban", content: 'Ocon', team: 'alpine'},
            'alonso': { id: 'alonso', fname: "Fernando", content: 'Alonso', team: 'alpine'},
            'stroll': { id: 'stroll', fname: "Lance", content: 'Stroll', team: 'aston_martin'},
            'mazepin': { id: 'mazepin', fname: "Nikita", content: 'Mazepin', team: 'haas'},
            'giovinazzi': { id: 'giovinazzi', fname: "Antonio", content: 'Giovinazzi', team: 'alfa_romeo'},
            'tsunoda': { id: 'tsunoda', fname: "Yuki", content: 'Tsunoda', team: 'alphaTauri'},
            'latifi': { id: 'latifi', fname: "Nicholas", content: 'Latifi', team: 'williams'},
            'russell': { id: 'russell', fname: "George", content: 'Russell', team: 'williams'}
        },
        defaultIds1: ['hamilton', 'bottas', 'verstappen', 'leclerc', 'vettel', 'gasly', 'sainz', "schumacher", "ricciardo",
            'perez'],
        defaultIds2: ['norris', 'raikkonen', 'ocon', 'alonso', 'stroll', 'mazepin', 'giovinazzi', 'tsunoda',
            'latifi', 'russell'],
        qualifying: {
            columns: {
                'drivers1': {
                    id: 'drivers1',
                    title: "Drivers",
                    driverIds: ['hamilton', 'bottas', 'verstappen', 'leclerc', 'vettel', 'gasly', 'sainz', "schumacher", "ricciardo",
                        'perez']
                },
                'drivers2': {
                    id: 'drivers2',
                    title: "Drivers (cont.)",
                    driverIds: ['norris', 'raikkonen', 'ocon', 'alonso', 'stroll', 'mazepin', 'giovinazzi', 'tsunoda',
                        'latifi', 'russell']
                },
                'q1': {
                    id: 'q1',
                    title: 'Q1',
                    driverIds: []
                },
                'q2': {
                    id: 'q2',
                    title: "Q2",
                    driverIds: []
                },
                'q3': {
                    id: 'q3',
                    title: "Q3",
                    driverIds: []
                }
            },
            columnOrder: ['drivers1', 'drivers2', 'q1', 'q2', 'q3']
        },
        qualOrder: {
            columns: {
                'drivers1': {
                    id: 'drivers1',
                    title: "Drivers",
                    driverIds: ['hamilton', 'bottas', 'verstappen', 'leclerc', 'vettel', 'gasly', 'sainz', "schumacher", "ricciardo",
                        'perez']
                },
                'drivers2': {
                    id: 'drivers2',
                    title: "Drivers (cont.)",
                    driverIds: ['norris', 'raikkonen', 'ocon', 'alonso', 'stroll', 'mazepin', 'giovinazzi', 'tsunoda',
                        'latifi', 'russell']
                },
                'qualOrder': {
                    id: 'qualOrder',
                    title: 'Top 10 Qualifying Order',
                    driverIds: []
                },
            },
            columnOrder: ['drivers1', 'drivers2', 'qualOrder']
        },
        race: {
            columns: {
                'drivers1': {
                    id: 'drivers1',
                    title: "Drivers",
                    driverIds: ['hamilton', 'bottas', 'verstappen', 'leclerc', 'vettel', 'gasly', 'sainz', "schumacher", "ricciardo",
                        'perez']
                },
                'drivers2': {
                    id: 'drivers2',
                    title: "Drivers (cont.)",
                    driverIds: ['norris', 'raikkonen', 'ocon', 'alonso', 'stroll', 'mazepin', 'giovinazzi', 'tsunoda',
                        'latifi', 'russell']
                },
                'finishPosition': {
                    id: 'finishPosition',
                    title: 'Finish position (select Fastest Lap)',
                    driverIds: []
                },
            },
            columnOrder: ['drivers1', 'drivers2', 'finishPosition']
        },
        dnfs: {
            columns: {
                'drivers1': {
                    id: 'drivers1',
                    title: "Drivers",
                    driverIds: ['hamilton', 'bottas', 'verstappen', 'leclerc', 'vettel', 'gasly', 'sainz', "schumacher", "ricciardo",
                        'perez']
                },
                'drivers2': {
                    id: 'drivers2',
                    title: "Drivers (cont.)",
                    driverIds: ['norris', 'raikkonen', 'ocon', 'alonso', 'stroll', 'mazepin', 'giovinazzi', 'tsunoda',
                        'latifi', 'russell']
                },
                'dnfs': {
                    id: 'dnfs',
                    title: 'DNFs',
                    driverIds: []
                },
            },
            columnOrder: ['drivers1', 'drivers2', 'dnfs']
        },
        sprint_q: {
            columns: {
                'drivers1': {
                    id: 'drivers1',
                    title: "Drivers",
                    driverIds: ['hamilton', 'bottas', 'verstappen', 'leclerc', 'vettel', 'gasly', 'sainz', "schumacher", "ricciardo",
                        'perez']
                },
                'drivers2': {
                    id: 'drivers2',
                    title: "Drivers (cont.)",
                    driverIds: ['norris', 'raikkonen', 'ocon', 'alonso', 'stroll', 'mazepin', 'giovinazzi', 'tsunoda',
                        'latifi', 'russell']
                },
                'sprint_q': {
                    id: 'sprint_q',
                    title: 'Spring Qualifying',
                    driverIds: []
                }
            },
            columnOrder: ['drivers1', 'drivers2', 'sprint_q']
        },
        all: {
            qualifying: {
                columns: {
                    'q1': {
                        id: 'q1',
                        title: 'Q1',
                        driverIds: []
                    },
                    'q2': {
                        id: 'q2',
                        title: "Q2",
                        driverIds: []
                    },
                    'q3': {
                        id: 'q3',
                        title: "Q3",
                        driverIds: []
                    }
                },
                columnOrder: ['q1', 'q2', 'q3']
            },
            qualOrder: {
                columns: {
                    'qualOrder': {
                        id: 'qualOrder',
                        title: 'Top 10 Qualifying Order',
                        driverIds: []
                    },
                },
                columnOrder: ['qualOrder']
            },
            race: {
                columns: {
                    'finishPosition': {
                        id: 'finishPosition',
                        title: 'Finish position (select Fastest Lap)',
                        driverIds: []
                    },
                },
                columnOrder: ['finishPosition']
            },
            dnfs: {
                columns: {
                    'dnfs': {
                        id: 'dnfs',
                        title: 'DNFs',
                        driverIds: []
                    },
                },
                columnOrder: ['dnfs']
            },
            sprint_q: {
                columns: {
                    'sprint_q': {
                        id: 'sprint_q',
                        title: 'Sprint Qualifying',
                        driverIds: []
                    }
                },
                columnOrder: ['sprint_q']
            }
        },
        fastestLap: "",
    },
    2022: {
        drivers: {
            'hamilton': { id: 'hamilton', fname: 'Lewis', content: 'Hamilton', team: 'mercedes' },
            'bottas': { id: 'bottas', fname: 'Valtteri', content: 'Bottas', team: 'alfa_romeo' },
            'verstappen': { id: 'verstappen', fname: 'Max', content: "Verstappen", team: 'red_bull' },
            'leclerc': { id: 'leclerc', fname: "Charles", content: 'Leclerc', team: 'ferrari'},
            'vettel': { id: 'vettel', fname: "Sebastian", content: 'Vettel', team: 'aston_martin'},
            'gasly': { id: 'gasly', fname: "Pierre", content: 'Gasly', team: 'alphaTauri'},
            'sainz': { id: 'sainz', fname: "Carlos", content: "Sainz", team: 'ferrari'},
            'schumacher': { id: 'schumacher', fname: "Mick", content: 'Schumacher', team: 'haas'},
            'ricciardo': { id: 'ricciardo', fname: "Daniel", content: 'Ricciardo', team: 'mclaren'},
            'perez': { id: 'perez', fname: "Sergio", content: 'Perez', team: 'red_bull'},
            'norris': { id: 'norris', fname: "Lando", content: 'Norris', team: 'mclaren'},
            'zhou': { id: 'zhou', fname: "Guanyu", content: 'Zhou', team: 'alfa_romeo'},
            'ocon': { id: 'ocon', fname: "Esteban", content: 'Ocon', team: 'alpine'},
            'alonso': { id: 'alonso', fname: "Fernando", content: 'Alonso', team: 'alpine'},
            'stroll': { id: 'stroll', fname: "Lance", content: 'Stroll', team: 'aston_martin'},
            'magnussen': { id: 'magnussen', fname: "Kevin", content: 'Magnussen', team: 'haas'},
            'albon': { id: 'albon', fname: "Alex", content: 'Albon', team: 'williams'},
            'tsunoda': { id: 'tsunoda', fname: "Yuki", content: 'Tsunoda', team: 'alphaTauri'},
            'latifi': { id: 'latifi', fname: "Nicholas", content: 'Latifi', team: 'williams'},
            'russell': { id: 'russell', fname: "George", content: 'Russell', team: 'mercedes'}
        },
        defaultIds1: ['hamilton', 'russell', 'verstappen', 'perez', 'leclerc', 'sainz', 'norris', "ricciardo", "ocon", 'alonso'],
        defaultIds2: ['gasly', 'tsunoda', 'stroll', 'vettel', 'albon', 'latifi', 'bottas', 'zhou', 'schumacher', 'magnussen'],
        qualifying: {
            columns: {
                'drivers1': {
                    id: 'drivers1',
                    title: "Drivers",
                    driverIds: ['hamilton', 'russell', 'verstappen', 'perez', 'leclerc', 'sainz', 'norris', "ricciardo", "ocon", 'alonso']
                },
                'drivers2': {
                    id: 'drivers2',
                    title: "Drivers (cont.)",
                    driverIds: ['gasly', 'tsunoda', 'stroll', 'vettel', 'albon', 'latifi', 'bottas', 'zhou', 'schumacher', 'magnussen']
                },
                'q1': {
                    id: 'q1',
                    title: 'Q1',
                    driverIds: []
                },
                'q2': {
                    id: 'q2',
                    title: "Q2",
                    driverIds: []
                },
                'q3': {
                    id: 'q3',
                    title: "Q3",
                    driverIds: []
                }
            },
            columnOrder: ['drivers1', 'drivers2', 'q1', 'q2', 'q3']
        },
        qualOrder: {
            columns: {
                'drivers1': {
                    id: 'drivers1',
                    title: "Drivers",
                    driverIds: ['hamilton', 'russell', 'verstappen', 'perez', 'leclerc', 'sainz', 'norris', "ricciardo", "ocon", 'alonso']
                },
                'drivers2': {
                    id: 'drivers2',
                    title: "Drivers (cont.)",
                    driverIds: ['gasly', 'tsunoda', 'stroll', 'vettel', 'albon', 'latifi', 'bottas', 'zhou', 'schumacher', 'magnussen']
                },
                'qualOrder': {
                    id: 'qualOrder',
                    title: 'Top 10 Qualifying Order',
                    driverIds: []
                },
            },
            columnOrder: ['drivers1', 'drivers2', 'qualOrder']
        },
        race: {
            columns: {
                'drivers1': {
                    id: 'drivers1',
                    title: "Drivers",
                    driverIds: ['hamilton', 'russell', 'verstappen', 'perez', 'leclerc', 'sainz', 'norris', "ricciardo", "ocon", 'alonso']
                },
                'drivers2': {
                    id: 'drivers2',
                    title: "Drivers (cont.)",
                    driverIds: ['gasly', 'tsunoda', 'stroll', 'vettel', 'albon', 'latifi', 'bottas', 'zhou', 'schumacher', 'magnussen']
                },
                'finishPosition': {
                    id: 'finishPosition',
                    title: 'Finish position (select Fastest Lap)',
                    driverIds: []
                },
            },
            columnOrder: ['drivers1', 'drivers2', 'finishPosition']
        },
        dnfs: {
            columns: {
                'drivers1': {
                    id: 'drivers1',
                    title: "Drivers",
                    driverIds: ['hamilton', 'russell', 'verstappen', 'perez', 'leclerc', 'sainz', 'norris', "ricciardo", "ocon", 'alonso']
                },
                'drivers2': {
                    id: 'drivers2',
                    title: "Drivers (cont.)",
                    driverIds: ['gasly', 'tsunoda', 'stroll', 'vettel', 'albon', 'latifi', 'bottas', 'zhou', 'schumacher', 'magnussen']
                },
                'dnfs': {
                    id: 'dnfs',
                    title: 'DNFs',
                    driverIds: []
                },
            },
            columnOrder: ['drivers1', 'drivers2', 'dnfs']
        },
        sprint_q: {
            columns: {
                'drivers1': {
                    id: 'drivers1',
                    title: "Drivers",
                    driverIds: ['hamilton', 'russell', 'verstappen', 'perez', 'leclerc', 'sainz', 'norris', "ricciardo", "ocon", 'alonso']
                },
                'drivers2': {
                    id: 'drivers2',
                    title: "Drivers (cont.)",
                    driverIds: ['gasly', 'tsunoda', 'stroll', 'vettel', 'albon', 'latifi', 'bottas', 'zhou', 'schumacher', 'magnussen']
                },
                'sprint_q': {
                    id: 'sprint_q',
                    title: 'Spring Qualifying',
                    driverIds: []
                }
            },
            columnOrder: ['drivers1', 'drivers2', 'sprint_q']
        },
        all: {
            qualifying: {
                columns: {
                    'q1': {
                        id: 'q1',
                        title: 'Q1',
                        driverIds: []
                    },
                    'q2': {
                        id: 'q2',
                        title: "Q2",
                        driverIds: []
                    },
                    'q3': {
                        id: 'q3',
                        title: "Q3",
                        driverIds: []
                    }
                },
                columnOrder: ['q1', 'q2', 'q3']
            },
            qualOrder: {
                columns: {
                    'qualOrder': {
                        id: 'qualOrder',
                        title: 'Top 10 Qualifying Order',
                        driverIds: []
                    },
                },
                columnOrder: ['qualOrder']
            },
            race: {
                columns: {
                    'finishPosition': {
                        id: 'finishPosition',
                        title: 'Finish position (select Fastest Lap)',
                        driverIds: []
                    },
                },
                columnOrder: ['finishPosition']
            },
            dnfs: {
                columns: {
                    'dnfs': {
                        id: 'dnfs',
                        title: 'DNFs',
                        driverIds: []
                    },
                },
                columnOrder: ['dnfs']
            },
            sprint_q: {
                columns: {
                    'sprint_q': {
                        id: 'sprint_q',
                        title: 'Sprint Qualifying',
                        driverIds: []
                    }
                },
                columnOrder: ['sprint_q']
            }
        },
        fastestLap: "",
    },
    2023: {
        drivers: {
            'hamilton': { id: 'hamilton', fname: 'Lewis', content: 'Hamilton', team: 'mercedes' },
            'bottas': { id: 'bottas', fname: 'Valtteri', content: 'Bottas', team: 'alfa_romeo' },
            'verstappen': { id: 'verstappen', fname: 'Max', content: "Verstappen", team: 'red_bull' },
            'leclerc': { id: 'leclerc', fname: "Charles", content: 'Leclerc', team: 'ferrari'},
            'gasly': { id: 'gasly', fname: "Pierre", content: 'Gasly', team: 'alpine'},
            'sainz': { id: 'sainz', fname: "Carlos", content: "Sainz", team: 'ferrari'},
            'perez': { id: 'perez', fname: "Sergio", content: 'Perez', team: 'red_bull'},
            'norris': { id: 'norris', fname: "Lando", content: 'Norris', team: 'mclaren'},
            'zhou': { id: 'zhou', fname: "Guanyu", content: 'Zhou', team: 'alfa_romeo'},
            'ocon': { id: 'ocon', fname: "Esteban", content: 'Ocon', team: 'alpine'},
            'alonso': { id: 'alonso', fname: "Fernando", content: 'Alonso', team: 'aston_martin'},
            'stroll': { id: 'stroll', fname: "Lance", content: 'Stroll', team: 'aston_martin'},
            'magnussen': { id: 'magnussen', fname: "Kevin", content: 'Magnussen', team: 'haas'},
            'albon': { id: 'albon', fname: "Alex", content: 'Albon', team: 'williams'},
            'tsunoda': { id: 'tsunoda', fname: "Yuki", content: 'Tsunoda', team: 'alphaTauri'},
            'russell': { id: 'russell', fname: "George", content: 'Russell', team: 'mercedes'},
            'hulkenberg': { id: 'hulkenberg', fname: 'Nico', content: 'Hulkenberg', team: 'haas' },
            'piastri': { id: 'piastri', fname: 'Oscar', content: 'Piastri', team: 'mclaren' },
            'devries': { id: 'devries', fname: 'Nyck', content: 'De Vries', team: 'alphaTauri' },
            'sargeant': { id: 'sargeant', fname: 'Logan', content: 'Sargeant', team: 'williams' }
        },
        defaultIds1: DEFAULT_IDS_1,
        defaultIds2: DEFAULT_IDS_2,
        qualifying: {
            columns: {
                'drivers1': {
                    id: 'drivers1',
                    title: "Drivers",
                    driverIds: DEFAULT_IDS_1
                },
                'drivers2': {
                    id: 'drivers2',
                    title: "Drivers (cont.)",
                    driverIds: DEFAULT_IDS_2
                },
                'q1': {
                    id: 'q1',
                    title: 'Q1',
                    driverIds: []
                },
                'q2': {
                    id: 'q2',
                    title: "Q2",
                    driverIds: []
                },
                'q3': {
                    id: 'q3',
                    title: "Q3",
                    driverIds: []
                }
            },
            columnOrder: ['drivers1', 'drivers2', 'q1', 'q2', 'q3']
        },
        qualOrder: {
            columns: {
                'drivers1': {
                    id: 'drivers1',
                    title: "Drivers",
                    driverIds: DEFAULT_IDS_1
                },
                'drivers2': {
                    id: 'drivers2',
                    title: "Drivers (cont.)",
                    driverIds: DEFAULT_IDS_2
                },
                'qualOrder': {
                    id: 'qualOrder',
                    title: 'Top 10 Qualifying Order',
                    driverIds: []
                },
            },
            columnOrder: ['drivers1', 'drivers2', 'qualOrder']
        },
        race: {
            columns: {
                'drivers1': {
                    id: 'drivers1',
                    title: "Drivers",
                    driverIds: DEFAULT_IDS_1
                },
                'drivers2': {
                    id: 'drivers2',
                    title: "Drivers (cont.)",
                    driverIds: DEFAULT_IDS_2
                },
                'finishPosition': {
                    id: 'finishPosition',
                    title: 'Finish position (select Fastest Lap)',
                    driverIds: []
                },
            },
            columnOrder: ['drivers1', 'drivers2', 'finishPosition']
        },
        dnfs: {
            columns: {
                'drivers1': {
                    id: 'drivers1',
                    title: "Drivers",
                    driverIds: DEFAULT_IDS_1
                },
                'drivers2': {
                    id: 'drivers2',
                    title: "Drivers (cont.)",
                    driverIds: DEFAULT_IDS_2
                },
                'dnfs': {
                    id: 'dnfs',
                    title: 'DNFs',
                    driverIds: []
                },
            },
            columnOrder: ['drivers1', 'drivers2', 'dnfs']
        },
        sprint_q: {
            columns: {
                'drivers1': {
                    id: 'drivers1',
                    title: "Drivers",
                    driverIds: DEFAULT_IDS_1
                },
                'drivers2': {
                    id: 'drivers2',
                    title: "Drivers (cont.)",
                    driverIds: DEFAULT_IDS_2
                },
                'sprint_q': {
                    id: 'sprint_q',
                    title: 'Spring Qualifying',
                    driverIds: []
                }
            },
            columnOrder: ['drivers1', 'drivers2', 'sprint_q']
        },
        all: {
            qualifying: {
                columns: {
                    'q1': {
                        id: 'q1',
                        title: 'Q1',
                        driverIds: []
                    },
                    'q2': {
                        id: 'q2',
                        title: "Q2",
                        driverIds: []
                    },
                    'q3': {
                        id: 'q3',
                        title: "Q3",
                        driverIds: []
                    }
                },
                columnOrder: ['q1', 'q2', 'q3']
            },
            qualOrder: {
                columns: {
                    'qualOrder': {
                        id: 'qualOrder',
                        title: 'Top 10 Qualifying Order',
                        driverIds: []
                    },
                },
                columnOrder: ['qualOrder']
            },
            race: {
                columns: {
                    'finishPosition': {
                        id: 'finishPosition',
                        title: 'Finish position (select Fastest Lap)',
                        driverIds: []
                    },
                },
                columnOrder: ['finishPosition']
            },
            dnfs: {
                columns: {
                    'dnfs': {
                        id: 'dnfs',
                        title: 'DNFs',
                        driverIds: []
                    },
                },
                columnOrder: ['dnfs']
            },
            sprint_q: {
                columns: {
                    'sprint_q': {
                        id: 'sprint_q',
                        title: 'Sprint Qualifying',
                        driverIds: []
                    }
                },
                columnOrder: ['sprint_q']
            }
        },
        fastestLap: "",
    }
};

export const teamColors = {
    'mercedes': '#00D2BE',
    'ferrari': '#DC0000',
    'red_bull': '#0600ef',
    'alpine': "#0090ff",
    'haas': '#ffffff',
    'aston_martin': '#006f62',
    'alphaTauri': '#2b4562',
    'mclaren': '#FF8700',
    'alfa_romeo': '#960000',
    'williams': '#005aff'
};