import React from "react";

class About extends React.Component {

    render() {
        return (
            <div id={"about"}>
                <br/>
                This website started as a
            </div>
        )
    }
}

export default About;