import React, {Component} from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import Select from "@material-ui/core/Select";
import DriversPanel from "../DriversPanel";
import {DNFs, QUALIFYING, QUALORDER, RACE, SPRINT_Q} from "../../constants/values";
import {ScaleLoader} from "react-spinners";
import {css} from "@emotion/core";
import {Container, FormControl, InputLabel, MenuItem} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import {hasSprint} from "../../common/sprint_handler";

const options = [
    {value: QUALIFYING, name: "Qualifying"},
    {value: QUALORDER, name: "Qualifying order"},
    {value: RACE, name: "Race"},
    {value: DNFs, name: "DNFs"}
];

const sprint_options = [
    {value: QUALIFYING, name: "Qualifying"},
    {value: QUALORDER, name: "Qualifying order"},
    {value: RACE, name: "Race"},
    {value: DNFs, name: "DNFs"},
    {value: SPRINT_Q, name: "Sprint Qualifying"}
];

const override = css`
  display: block;
  margin: 10 auto;
`;

// function makeTheme(theme) {
//     return {
//         ...theme,
//         colors: {
//             ...theme.colors,
//             neutral0: '#1e1e1e', // background and selected text in dropdown
//             neutral20: "#ff484d", // outline when not clicked
//             neutral80: '#ffffff', // selected text
//             primary: '#ff484d', // selected background and border
//             primary25: '#ff2f36', // mouseover non-selected
//             primary50: "#ff1c23" // on click
//         }
//     }
// }

class ResultsInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            selected: "",
            selectedComponent: "",
            users: [],
            races: [],
            raceToPass: {
                id: "wait"
            },
            isAdmin: false,
            hasSprint: false
        }
    }

    updateHasSprint = (enabled) => {
        this.setState({ ...this.state, hasSprint: enabled})
    }

    componentDidMount() {
        const uid = firebase.auth().currentUser.uid
        const db = firebase.firestore()
        db.collection("users").doc(uid).get().then((doc) => {
            if (doc.exists) {
                const data = doc.data()
                if (data.admin) {
                    this.setState({
                        isAdmin: true
                    })
                }
            }
        })
    }

    render() {
        if (this.state.isAdmin) {
            if (!this.state.loaded) {
                this.setState({
                    ...this.state,
                    loaded: true
                });
                this.getRaces()

                return (
                    <div className={"loading"}>
                        <br/>
                        <ScaleLoader color={"#ff0000"} css={override} size={15}/>
                        <p>Loading...</p>
                    </div>
                );
            } // TODO: transition all pages to Containers and Grids to rectify the CSS mess
            return (
                <div className={"results"}>
                    <br/>
                    <Container maxWidth={"sm"}>
                        <Grid container justify={"center"} spacing={1}>
                            <Grid item xs={12}>
                                <h1>Results</h1>
                                now with new selectors<br/>
                                soon all selectors will look like this (they make theming consistent)
                            </Grid>
                            <Grid item>
                                <FormControl style={{minWidth: 120}}>
                                    <InputLabel id="race-select-label">Race</InputLabel>
                                    <Select className={"dropBox"}
                                            onChange={this._onSelect.bind(this)}
                                            value={this.state.selected}>
                                        {this.state.races.map((race) =>
                                            <MenuItem value={race.value} key={race.value}>{race.label}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl>
                                    <InputLabel id={"comp-select-label"}>Category</InputLabel>
                                    <Select className={"dropBox"} onChange={this._onSelectComponent.bind(this)}
                                            value={this.state.selectedComponent.value}>
                                        {this.state.hasSprint ? sprint_options.map((item, idx) =>
                                                <MenuItem value={item.value} key={item.value}>{item.name}</MenuItem>
                                            )
                                            : options.map((item, idx) =>
                                            <MenuItem value={item.value} key={item.value}>{item.name}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Container>
                    <br/>
                    <div className={'row'}>
                        <DriversPanel readOnly={false} key={this.state.raceToPass.id}
                                      isInput={true}
                                      race={this.state.raceToPass} user={"results"}
                                      component={this.state.selectedComponent}
                                      year={2023}
                                      className={"driverPanel"}/>
                    </div>
                </div>
            )
        }
        return (
            <div className={"homePage"}>
                <h2>Get out. Results Inputters only page.</h2>
                <h6>...how'd you even find this?</h6>
            </div>
        );
    }

    getRaces() {
        let races = []
        firebase.firestore().collection("races").where('year', '==', 2023).orderBy("race_time")
            .get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
                let data = doc.data()
                let race = {
                    value: doc.id,
                    label: data.name
                }
                races.push(race)
            });

            this.setState({
                ...this.state,
                races
            })
        });
    }

    _onSelect(event) {
        let passRace = {
            id: event.target.value,
            name: event.target.name
        }
        this.setState({
            ...this.state,
            selected: event.target.value,
            raceToPass: passRace,
            selectedUser: "",
            hasSprint: hasSprint(passRace)
        });
        this.forceUpdate();
    }

    _onSelectComponent(event) {
        this.setState({
            ...this.state,
            selectedComponent: event.target.value,
        });
        this.forceUpdate();
    }
}


export default ResultsInput;