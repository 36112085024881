export const LANDING = '/';
export const SIGN_OUT = '/signout';
export const PREDICT = '/predict';
export const LEADERBOARD = '/leaderboard';
export const ACCOUNT = '/account';
export const ARCHIVE = '/archive';
export const RULES = '/rules';
export const ABOUT = '/about';
export const RESULTS_INPUT = '/input';
export const CHANGELOG = '/whats-new';
export const ADD_RACE = '/add';
export const BINGO = '/bingo';