import React from "react";
import './leaderboard.css';
import firebase from "firebase/app";
import "firebase/firestore";
import { firebaseConfig } from "../../../constants/values";
import { DataGrid } from "@material-ui/data-grid";
import { ScaleLoader } from "react-spinners";
import { withStyles } from "@material-ui/core/styles";
import { Popover, Typography} from "@material-ui/core";
import { Link } from 'react-router-dom';
import {hasSprint} from "../../../common/sprint_handler";
import Select, { SingleValue } from 'react-select';


!firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();

const db = firebase.firestore();


const styles = (theme: any) => ({
    popover: {
        pointerEvents: 'none'
    },
    paper: {
        padding: theme.spacing(1)
    }
});

function makeTheme(theme: any) {
    return {
        ...theme,
        colors: {
            ...theme.colors,
            neutral0: '#1e1e1e', // background and selected text in dropdown
            neutral20: "#ff484d", // outline when not clicked
            neutral80: '#ffffff', // selected text
            primary: '#ff484d', // selected background and border
            primary25: '#ff2f36', // mouseover non-selected
            primary50: "#ff1c23" // on click
        }
    }
}

const years = [
    { value: 2023, label: '2023'},
    { value: 2022, label: '2022'},
    { value: 2021, label: '2021'}
]

type leaderboardState = {
    races: any[],
    users: any,
    loaded: boolean,
    anchorEl: any,
    raceObjects: any,
    columns: any[],
    rows: any[],
    selectedYear: number,
    openedPopoverId: string | null
}

type renderParams = {
    api: any,
    cellMode: string,
    colDef: any,
    field: string,
    formattedValue: number,
    getValue: Function,
    hasFocus: boolean,
    id: string,
    row: {
        id: string,
        total: number,
        user: string
    },
    tabIndex: number,
    value: number
}

// score arrays: [qualScore, qualOrderScore, finishScore, lapScore, dnfScore]

class Leaderboard extends React.Component<any, leaderboardState> {
    constructor(props: any) {
        super(props);
        this.state = {
            races: [],
            users: {},
            loaded: false,
            anchorEl: null,
            raceObjects: {},
            columns: [],
            rows: [],
            selectedYear: 2023,
            openedPopoverId: null
        };

        this.handlePopoverOpen = this.handlePopoverOpen.bind(this);
        this.handlePopoverClose = this.handlePopoverClose.bind(this);
        this.generatePopover = this.generatePopover.bind(this);
    }

    async componentDidMount() {
        await this.getRaces()
    }

    handlePopoverOpen(event: any, popoverId: string) {
        this.setState({
            anchorEl: event.currentTarget,
            openedPopoverId: popoverId
        });
    }

    handlePopoverClose() {
        this.setState({
            anchorEl: null,
            openedPopoverId: null
        });
    }

    async getRaces() {
        db.collection("races").where("year", "==", this.state.selectedYear).orderBy("race_time", "asc").get().then((querySnapshot) => {
            let races: any[] = [];
            let objects: any = {};
            querySnapshot.forEach(race => {
                races.push({id: race.id, name: race.data()['name']})
                let data = race.data()
                objects[race.id] = {
                    value: race.id,
                    id: race.id,
                    label: data.name,
                    name: data.name,
                    q1: data.q1,
                    q2: data.q2,
                    q3: data.q3,
                    qualOrder: data.qualOrderResults,
                    finishPosition: data.finishPosition,
                    fastestLap: data.fastestLap,
                    dnfs: data.dnfsResults
                }
            })
            this.getUsers(races).then(() => {
                this.setState({raceObjects: objects})
            })
        })
    }

    async getUsers(races: any[]) {
        db.collection("users").get().then((querySnapshot) => {
            let users: any = {};
            let i = 0;
            querySnapshot.forEach(userDoc => {
                users[userDoc.id] = {
                    name: userDoc.data()['name']
                }
                db.collection('users').doc(userDoc.id).collection("scores").doc(this.state.selectedYear.toString()).get().then((scoreDoc) => {
                    races.forEach(race => {
                        if (scoreDoc.exists) {
                            let scoresData = scoreDoc.data() || {};
                            let scores = scoresData[race.id] != null ? scoresData[race.id] : {};
                            let qualScore = scores.qualScore != null ? scores.qualScore : 0;
                            let qualOrderScore = scores.qualOrderScore != null ? scores.qualOrderScore : 0;
                            let finishScore = scores.finishScore != null ? scores.finishScore : 0;
                            let lapScore = scores.lapScore != null ? scores.lapScore : 0;
                            let dnfScore = scores.dnfScore != null ? scores.dnfScore : 0;
                            let sprint_q_score = scores.sprint_q_score != null ? scores.sprint_q_score : 0;
                            console.log(race)
                            if (hasSprint(race)) {
                                users[userDoc.id][race.id.toString()] = [qualScore, qualOrderScore, finishScore, lapScore, dnfScore, sprint_q_score];
                            } else {
                                users[userDoc.id][race.id.toString()] = [qualScore, qualOrderScore, finishScore, lapScore, dnfScore];
                            }
                        } else {
                            // console.log("scoreDoc no for " + userDoc.id)
                            if (hasSprint(race)) {
                                users[userDoc.id][race.id.toString()] = [0, 0, 0, 0, 0, 0];
                            } else {
                                users[userDoc.id][race.id.toString()] = [0, 0, 0, 0, 0];
                            }
                        }
                    })
                    if (i === querySnapshot.size - 1) {
                        this.setState({
                            users: users,
                            races: races
                        }, () => {
                            this.makeTable(races, users);
                        })
                    } else {
                        i++
                    }
                })
            })
        })
    }

    generatePopover(scores: number[], hasSprint: boolean) {
        // [qualScore, qualOrderScore, finishScore, lapScore, dnfScore]
        return (
            <div>
                (click to view details)<br/>
                Q1-Q3: {scores[0]}<br/>
                Qualifying Order: {scores[1]}<br/>
                Finish Position: {scores[2]}<br/>
                Fastest Lap: {scores[3]}<br/>
                DNFs: {scores[4]}<br/>
                {hasSprint ? <div>Sprint Quali Score: {scores[5]}</div> : null}
            </div>
        )
    }

    makeTable(races: any[], users: any) {
        let columns = [];
        columns = [
            {field: 'user', headerName: 'User', width: 130},
            {field: 'total', headerName: 'Total', width: 130, type: 'number'}
        ];
        races.forEach((race, idx) => {
            columns.push({
                field: race.id,
                headerName: race.name,
                width: 130,
                renderCell: (params: renderParams) => {
                    // const test = params.getValue('user');
                    const { classes } = this.props;
                    return (
                        <div>
                            <div
                                aria-owns={Boolean(this.state.anchorEl) ? 'mouse-over-popover' : undefined}
                                aria-haspopup={"true"}
                                onMouseEnter={(e) => this.handlePopoverOpen(e, params.row.id + params.field)}
                                onMouseLeave={this.handlePopoverClose}
                            >
                                <Link to={{
                                    pathname: '/archive',
                                    // @ts-ignore
                                    state: {
                                        loaded: true,
                                        selected: this.state.raceObjects[params.field],
                                        raceToPass: this.state.raceObjects[params.field],
                                        races: this.state.raceObjects,
                                        users: users,
                                        selectedUser: params.row.id
                                    }
                                }} style={{ textDecoration: 'inherit', color: 'inherit' }}>{params.value}</Link>
                            </div>
                            <Popover
                                id={"mouse-over-popover"}
                                className={classes.popover}
                                classes={{
                                    paper: classes.paper
                                }}
                                open={this.state.openedPopoverId === params.row.id + params.field}
                                anchorEl={this.state.anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left'
                                }}
                                onClose={this.handlePopoverClose.bind(this)}
                                disableRestoreFocus
                            >
                                <Typography variant={"caption"}>{this.generatePopover(users[params.row.id][params.field], hasSprint(this.state.raceObjects[params.field]))}</Typography>
                            </Popover>
                        </div>
                    )
                }
            });
        })
        let rows = [];
        for (const [id, user] of Object.entries<any>(users)) {
            let user_row = {
                id: id,
                user: user['name'],
                total: 0
            }
            let user_total = 0;
            races.forEach((race, idx) => {
                let race_total = user[race.id].reduce((a: number, b: number) => a + b, 0);
                // @ts-ignore
                user_row[race.id] = race_total;
                user_total += race_total;
            })
            user_row.total = user_total;
            rows.push(user_row)
        }

        this.setState({
            loaded: true,
            columns: columns,
            rows: rows
        });
    }

    render() {
        return (
            <div className={'outer'}>
                <Select className={"dropBox"} onChange={this._onYearSelect.bind(this)}
                                    options={years}
                                    defaultValue={years[0]}
                                    theme={someTheme => makeTheme(someTheme)}/>
                <div className={"Leaderboard"}>
                    <h1>{this.state.selectedYear} Leaderboard</h1>
                    <div style={{height: 400, width: '100%'}}>
                        {this.state.loaded ?
                            <DataGrid rows={this.state.rows} columns={this.state.columns} pageSize={10}
                                      sortModel={[{field: 'total', sort: 'desc'}]}/> :
                            <div><h3>Computing...</h3>
                                <ScaleLoader color={'white'}/></div>}

                    </div>
                </div>
            </div>
        )
    }

    _onYearSelect(val: SingleValue<{ value: number; label: string; }>) {
        if (val?.value === this.state.selectedYear) {
            return
        }
        this.setState({
            ...this.state,
            selectedYear: val?.value || this.state.selectedYear,
            races: [],
            users: {},
            loaded: false,
            anchorEl: null,
            raceObjects: {},
            columns: [],
            rows: [],
        }, async () => {
            await this.getRaces()
        })
    }
}

// @ts-ignore
export default withStyles(styles)(Leaderboard);