/* eslint-disable no-loop-func */
/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import { DragDropContext } from 'react-beautiful-dnd';
import { driverData } from '../constants/data';
import styled from 'styled-components'
import Column from './common/column'
import { Button, FormControl, RadioGroup, TextField } from "@material-ui/core";
import { css } from '@emotion/core';
import { ScaleLoader } from "react-spinners";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Countdown from "react-countdown-now";
import { QUALIFYING, QUALORDER, RACE, DNFs, teams, SPRINT_Q } from '../constants/values';
import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/auth';
import { firebaseConfig } from "../constants/values";
import { hasSprint } from "../common/sprint_handler";

const _ = require('lodash')

!firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();


const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
`;

const types = [
    "qualifying",
    "qualOrder",
    "race",
    "all",
    "dnfs",
    "sprint_q"
];

const times = [
    "qual_time",
    "q_order_time",
    "race_time",
    "all",
    "race_time",
    "sprint_q_time"
]

const strings = [
    'Qualifying',
    "Qualifying Order",
    "Race",
    "All",
    "DNFs",
    "Sprint Qualifying"
];

const db = firebase.firestore();


const override = css`
  display: block;
  margin: 0 0;
  border-color: red;
`;

let curr = "";
let needRefresh = false;

class DriversPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: JSON.parse(JSON.stringify(driverData[this.props.year])),
            submit: <p>Submit</p>,
            race: {},
            qualifyingOpen: true,
            fastestLap: null,
            fixed: false,
            gotPredictions: false,
            gotData: false,
            loaded: false,
            isAdmin: false,
            timeToExtend: 5
        };
    }

    componentDidMount() {
        if ((this.props?.user !== "" && this.props?.user != null) || !this.props?.readOnly) {
            this.getData().then(() => {
                // this.state.gotData = true;
                this.setState({ ...this.state, gotData: true });
            })
        }

        const uid = firebase.auth()?.currentUser?.uid
        if (!uid) {
            return
        }
        const db = firebase.firestore()
        db.collection("users").doc(uid).get().then((doc) => {
            if (doc.exists) {
                const data = doc.data()
                if (data.admin) {
                    this.setState({
                        isAdmin: true
                    })
                }
            }
        })
    }

    isDropDisabled(column) {
        const num = column.driverIds.length;
        switch (column.id) {
            case 'drivers1':
                return num === 10;
            case 'drivers2':
                return num === 10;
            case 'q1':
                return num === 5;
            case 'q2':
                return num === 5;
            case 'q3':
                return num === 10;
            case 'qualOrder':
                return num === 10;
            case 'finishPosition':
                return num === 20;
            case "dnfs":
                if (this.props.user === "results") {
                    return num === 20;
                }
                return num === 3;
            case 'sprint_q':
                return num === 20;
            default:
                return false;
        }
    };

    onDragEnd = result => {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId) {
            const start = this.state.data[types[this.props.component]].columns[source.droppableId];

            const newDriverIds = Array.from(start.driverIds);
            newDriverIds.splice(source.index, 1);
            newDriverIds.splice(destination.index, 0, draggableId);

            const newColumn = {
                ...start,
                driverIds: newDriverIds
            };

            const newState = {
                ...this.state,
                data: {
                    ...this.state.data,
                    [types[this.props.component]]: {
                        ...this.state.data[types[this.props.component]],
                        columns: {
                            ...this.state.data[types[this.props.component]].columns,
                            [newColumn.id]: newColumn
                        }
                    }
                }
            };
            // this.state.data[types[this.props.component]].columns[newColumn.id] = newColumn;

            this.setState(newState);
            return
        }

        const start = this.state.data[types[this.props.component]].columns[source.droppableId];
        const finish = this.state.data[types[this.props.component]].columns[destination.droppableId];

        if (start === finish) {
            const newDriverIds = Array.from(start.driverIds);
            newDriverIds.splice(source.index, 1);
            newDriverIds.splice(destination.index, 0, draggableId);

            const newColumn = {
                ...start,
                driverIds: newDriverIds
            };

            const newState = {
                ...this.state,
                data: {
                    ...this.state.data,
                    [types[this.props.component]]: {
                        ...this.state.data[types[this.props.component]],
                        columns: {
                            ...this.state.data[types[this.props.component]].columns,
                            [newColumn.id]: newColumn
                        }
                    }
                }
            };
            // this.state.data[types[this.props.component]].columns[newColumn.id] = newColumn;

            this.setState(newState);
            return
        }

        // Moving from one list to another

        if (this.isDropDisabled(finish)) {
            return
        }

        const startDriverIds = Array.from(start.driverIds);
        startDriverIds.splice(source.index, 1);
        const newStart = {
            ...start,
            driverIds: startDriverIds
        };

        const finishTaskIds = Array.from(finish.driverIds);
        finishTaskIds.splice(destination.index, 0, draggableId);
        const newFinish = {
            ...finish,
            driverIds: finishTaskIds
        };

        const newState = {
            ...this.state,
            data: {
                ...this.state.data,
                [types[this.props.component]]: {
                    ...this.state.data[types[this.props.component]],
                    columns: {
                        ...this.state.data[types[this.props.component]].columns,
                        [newStart.id]: newStart,
                        [newFinish.id]: newFinish
                    }
                }
            }
        };
        this.setState(newState)
    };

    render() {
        if ((!this.state.loaded || (!this.state.race['qual_time'] && !this.props.readOnly && !this.props.isInput))) {
            // if (((this.props.user !== "" && this.props.user != null) || !this.props.readOnly) && !this.state.gotData) {
            //     this.setState({
            //         ...this.state,
            //         gotData: true
            //     }, () => {
            //         this.getData()
            //     })
            // }
            return (
                <div className={"loading"}>
                    <br />
                    <ScaleLoader color={"#ff0000"} css={override} size={15} />
                    <p>{this.props.readOnly ? "Awaiting selection..." : "Loading..."}</p>
                </div>
            );
        } else {
            if (!this.props.readOnly) {
                const handleChange = event => {
                    this.setState({
                        ...this.state,
                        fastestLap: event.target.value
                    });
                };
                if (!this.state.fixed) {
                    // this.setState({
                    //     data: JSON.parse(JSON.stringify(driverData)),
                    //     fixed: true
                    // })
                }
                if (this.props.user === "results") {
                    if (this.props.race.id === "wait" || this.props.component === "") {
                        return (
                            <div className={"loading"}>
                                <br />
                                <ScaleLoader color={"#ff0000"} css={override} size={15} />
                                <p>Awaiting selection...</p>
                            </div>
                        );
                    }
                    /** BEGIN INPUT CODE **/
                    return (
                        <div className={"predict"}>
                            <div className={"predictionPanel"}>
                                <DragDropContext onDragEnd={this.onDragEnd}>
                                    <Container>
                                        {this.state.data[types[this.props.component]].columnOrder.map(columnId => {
                                            const column = this.state.data[types[this.props.component]].columns[columnId];
                                            const drivers = column.driverIds.map(driverId => this.state.data.drivers[driverId]);
                                            return (
                                                <FormControl component={"fieldset"} className={"formControl"}>
                                                    <RadioGroup aria-label={"fastestLap"} name={"fastestLap1"}
                                                        value={this.state.fastestLap}
                                                        onChange={handleChange}>
                                                        <Column
                                                            disabled={false}
                                                            key={column.id}
                                                            user={this.props.user}
                                                            bools={this.state.bools}
                                                            race={this.state.race}
                                                            column={column} drivers={drivers} />
                                                    </RadioGroup>
                                                </FormControl>
                                            )
                                        })}
                                    </Container>
                                </DragDropContext>
                            </div>
                            <div className={"row"} id={"submit-bar"}>
                                <Button
                                    disabled={false}
                                    style={{ height: 50, width: 80, margin: 10 }}
                                    className={"button"}
                                    variant={'contained'}
                                    onClick={this.process.bind(this)}>{this.state.submit}</Button>
                                <Button
                                    disabled={false}
                                    className={"button"}
                                    onClick={this.reset.bind(this)}>Reset</Button>
                            </div>
                        </div>
                    );
                    /** END INPUT CODE **/
                }
                return (
                    <div className={"predict"}>
                        <h3>Predicting: {this.state.race.name}</h3>
                        <div>{new Date() <= this.state.race[times[this.props.component]].toDate() ?
                            <h3>{strings[this.props.component]}<br />
                                Can predict
                                until {this.state.race[times[this.props.component]].toDate().toLocaleString("en-US")}
                                <br /><Countdown renderer={this.renderer}
                                    date={this.state.race[times[this.props.component]].toDate()} /></h3>
                            :
                            <h3>{strings[this.props.component]}<br />Predictions have closed
                                for {strings[this.props.component].toLowerCase()}
                            </h3>}</div>
                        <div className={"predictionPanel"}>
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Container>
                                    {this.state.data[types[this.props.component]].columnOrder.map(columnId => {
                                        const column = this.state.data[types[this.props.component]].columns[columnId];
                                        const drivers = column.driverIds.map(driverId => this.state.data.drivers[driverId]);
                                        return (
                                            <FormControl key={column.id + "_formControl"} component={"fieldset"}
                                                className={"formControl"}>
                                                <RadioGroup aria-label={"fastestLap"} name={"fastestLap1"}
                                                    value={this.state.fastestLap} onChange={handleChange}>
                                                    <Column
                                                        disabled={new Date() > this.state.race[times[this.props.component]].toDate()}
                                                        key={column.id}
                                                        column={column}
                                                        bools={this.state.bools}
                                                        race={this.state.race}
                                                        drivers={drivers} />
                                                </RadioGroup>
                                            </FormControl>
                                        )
                                    })}
                                </Container>
                            </DragDropContext>
                        </div>
                        <div className={"row"} id={'submit-bar'}>
                            <Button
                                disabled={new Date() > this.state.race[times[this.props.component]].toDate()}
                                style={{ height: 50, width: 80, margin: 10 }}
                                className={"button"}
                                variant={'contained'}
                                onClick={this.process.bind(this)}>{this.state.submit}</Button>
                            <Button
                                disabled={new Date() > this.state.race[times[this.props.component]].toDate()}
                                className={"button"}
                                onClick={this.reset.bind(this)}>Reset</Button>
                            {this.state.isAdmin && (
                                <>
                                    <Button
                                        className="button"
                                        variant="outlined"
                                        color="secondary"
                                        style={{ margin: 10 }}
                                        disabled={this.state.timeToExtend <= 0}
                                        onClick={this.extendTime.bind(this)}>Extend +{this.state.timeToExtend} min</Button>
                                    <TextField
                                        label="Extend by"
                                        color="secondary"
                                        id="extend-time-by"
                                        value={this.state.timeToExtend}
                                        error={this.state.timeToExtend <= 0}
                                        onChange={(e) => { this.setState({ timeToExtend: e.target.value }) }}
                                    />
                                </>
                            )}
                        </div>
                        <br />
                    </div>
                );
            }

            if (needRefresh) {
                needRefresh = false;
            }
            if (this.props.user !== curr && !this.state.gotPredictions) {
                this.setState({
                    ...this.state,
                    data: JSON.parse(JSON.stringify(driverData)),
                    fastestLap: null,
                    gotPredictions: true
                }, () => {
                    if (this.props.isInput) {
                        this.getResults()
                    } else {
                        this.getPredictions(!this.props.readOnly);
                    }
                })
            }
            return (
                <div className={"archive"}>
                    <h4>
                        Qualifying Score: {this.state.qualScore}<br />
                        Qualifying Order Score: {this.state.qualOrderScore}<br />
                        Finish Position Score: {this.state.finishScore}<br />
                        Fastest Lap Score: {this.state.lapScore}<br />
                        DNFs Score: {this.state.dnfScore}
                        {hasSprint(this.state.race) ?
                            <div>Sprint Qualifying Score: {this.state.sprint_q_score}</div>
                            : null}
                    </h4>
                    <h3>Total
                        Score: {this.state.qualScore + this.state.qualOrderScore + this.state.finishScore + this.state.lapScore + this.state.dnfScore}</h3>
                    <div id={'archive-panel'}>
                        <FormControl component={"fieldset"} className={"formControl"}>
                            <RadioGroup aria-label={"fastestLap"} name={"fastestLap1"}
                                value={this.state.fastestLap || ""}>
                                <DragDropContext onDragEnd={this.onDragEnd}>
                                    <Container>
                                        {this.state.data[types[this.props.component]].qualifying.columnOrder.map(columnId => {
                                            const column = this.state.data[types[this.props.component]].qualifying.columns[columnId];
                                            if (column.id === "drivers1" || column.id === "drivers2") {
                                                return null;
                                            }
                                            const drivers = column.driverIds.map(driverId => this.state.data.drivers[driverId]);
                                            return (
                                                <Column disabled={true} key={column.id}
                                                    race={this.state.race}
                                                    bools={this.state.bools}
                                                    column={column} drivers={drivers} />
                                            )
                                        })}
                                    </Container>
                                </DragDropContext>
                                <div className={'row'}>
                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                        <Container>
                                            {this.state.data[types[this.props.component]].qualOrder.columnOrder.map(columnId => {
                                                const column = this.state.data[types[this.props.component]].qualOrder.columns[columnId];
                                                if (column.id === "drivers1" || column.id === "drivers2") {
                                                    return null;
                                                }
                                                const drivers = column.driverIds.map(driverId => this.state.data.drivers[driverId]);
                                                return (
                                                    <Column disabled={true} key={column.id}
                                                        race={this.state.race}
                                                        bools={this.state.bools}
                                                        column={column} drivers={drivers} />
                                                )
                                            })}
                                        </Container>
                                    </DragDropContext>
                                    {hasSprint(this.state.race) ?
                                        <DragDropContext onDragEnd={this.onDragEnd}>
                                            <Container>
                                                {this.state.data[types[this.props.component]].sprint_q.columnOrder.map(columnId => {
                                                    const column = this.state.data[types[this.props.component]].sprint_q.columns[columnId];
                                                    if (column.id === "drivers1" || column.id === "drivers2") {
                                                        return null;
                                                    }
                                                    const drivers = column.driverIds.map(driverId => this.state.data.drivers[driverId]);
                                                    return (
                                                        <Column disabled={true} key={column.id}
                                                            race={this.state.race}
                                                            bools={this.state.bools}
                                                            column={column} drivers={drivers} />
                                                    )
                                                })}
                                            </Container>
                                        </DragDropContext>
                                        : null
                                    }
                                </div>
                                <div className={"row"}>
                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                        <Container>
                                            {this.state.data[types[this.props.component]].race.columnOrder.map(columnId => {
                                                const column = this.state.data[types[this.props.component]].race.columns[columnId];
                                                if (column.id === "drivers1" || column.id === "drivers2") {
                                                    return null;
                                                }
                                                const drivers = column.driverIds.map(driverId => this.state.data.drivers[driverId]);
                                                return (
                                                    <Column disabled={true} key={column.id}
                                                        race={this.state.race}
                                                        bools={this.state.bools}
                                                        column={column} drivers={drivers} />
                                                )
                                            })}
                                        </Container>
                                    </DragDropContext>
                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                        <Container>
                                            {this.state.data[types[this.props.component]].dnfs.columnOrder.map(columnId => {
                                                const column = this.state.data[types[this.props.component]].dnfs.columns[columnId];
                                                if (column.id === "drivers1" || column.id === "drivers2") {
                                                    return null;
                                                }
                                                const drivers = column.driverIds.map(driverId => this.state.data.drivers[driverId]);
                                                return (
                                                    <Column disabled={true} key={column.id}
                                                        race={this.state.race}
                                                        bools={this.state.bools}
                                                        column={column} drivers={drivers} />
                                                )
                                            })}
                                        </Container>
                                    </DragDropContext>
                                </div>
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
            );
        }
    }

    renderer = ({ days, hours, minutes, seconds }) => {
        return <span>Remaining: {days} days {hours} {hours !== 1 ? "hours" : "hour"} {minutes} minutes {seconds} seconds</span>
    };

    process() {
        this.setState({
            ...this.state,
            submit: <ScaleLoader color={"#ff0000"} css={override} size={105} />
        });
        if (this.props.isInput) {
            this.sendResultsAndScores(this.props.component, this.state.data[types[this.props.component]].columns)
        } else {
            this.sendData(this.props.component, this.state.data[types[this.props.component]].columns);
        }
    };

    extendTime() {
        const newTime = firebase.firestore.Timestamp.fromDate(new Date(this.state.race[times[this.props.component]].toDate().getTime() + (this.state.timeToExtend * 60 * 1000)))
        db.collection("races").doc(this.state.race.id).set({
            [times[this.props.component]]: newTime,
        }, { merge: true }).then(() => {
            const newRace = { ...this.state.race }
            newRace[times[this.props.component]] = newTime;
            this.setState({ race: newRace })
        });
    }

    reset() {
        switch (this.props.component) {
            case QUALIFYING:
                this.state.data[types[this.props.component]].columns.q1.driverIds = [];
                this.state.data[types[this.props.component]].columns.q2.driverIds = [];
                this.state.data[types[this.props.component]].columns.q3.driverIds = [];
                this.state.data[types[this.props.component]].columns.drivers1.driverIds = [...this.state.data.defaultIds1];
                this.state.data[types[this.props.component]].columns.drivers2.driverIds = [...this.state.data.defaultIds2];
                break;
            case QUALORDER:
                this.state.data[types[this.props.component]].columns.qualOrder.driverIds = [];
                this.state.data[types[this.props.component]].columns.drivers1.driverIds = [...this.state.data.defaultIds1];
                this.state.data[types[this.props.component]].columns.drivers2.driverIds = [...this.state.data.defaultIds2];
                break;
            case RACE:
                this.state.data[types[this.props.component]].columns.finishPosition.driverIds = [];
                this.state.data[types[this.props.component]].columns.drivers1.driverIds = [...this.state.data.defaultIds1];
                this.state.data[types[this.props.component]].columns.drivers2.driverIds = [...this.state.data.defaultIds2];
                break;
            case DNFs:
                this.state.data[types[this.props.component]].columns.dnfs.driverIds = [];
                this.state.data[types[this.props.component]].columns.drivers1.driverIds = [...this.state.data.defaultIds1];
                this.state.data[types[this.props.component]].columns.drivers2.driverIds = [...this.state.data.defaultIds2];
                break;
            case SPRINT_Q:
                this.state.data[types[this.props.component]].columns.sprint_q.driverIds = [];
                this.state.data[types[this.props.component]].columns.drivers1.driverIds = [...this.state.data.defaultIds1];
                this.state.data[types[this.props.component]].columns.drivers2.driverIds = [...this.state.data.defaultIds2];
                break;
            default:
                break;
        }
        this.forceUpdate();
    };

    async getData() {
        if (!this.props.readOnly) {
            if (this.props.isInput) {
                this.setState({
                    ...this.state,
                    race: this.props.race
                }, () => {
                    this.getResults()
                })
                return;
            }
            let startDeadline = new Date();
            startDeadline.setMinutes(startDeadline.getMinutes() - 150) // race prediction opens 2.5 hrs after last race
            console.log(this.props)
            db.collection("races")
                .where('year', '==', this.props.year)
                .where("race_time", ">=", firebase.firestore.Timestamp.fromDate(startDeadline))
                .limit(1)
                .get().then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        this.setState({
                            ...this.state,
                            race: doc.data(),
                        })
                        this.state.race.id = doc.id;
                        console.log(this.state.race.id)

                        this.props.updateHasSprint(hasSprint(this.state.race));

                        this.getPredictions(!this.props.readOnly).then(() => {
                            console.log(this.state);
                        });
                    });
                });
        } else {
            this.setState({
                ...this.state,
                race: this.props.race
            }, () => {
                this.getPredictions(!this.props.readOnly)
            })
        }
    }

    async getResults() {
        let ref = db.collection("races").doc(this.state.race.id)

        ref.get().then((doc) => {
            if (doc.exists) {
                const q1 = doc.data()['q1'] != null ? doc.data()['q1'] : [];
                const q2 = doc.data()['q2'] != null ? doc.data()['q2'] : [];
                const q3 = doc.data()['q3'] != null ? doc.data()['q3'] : [];
                const qualOrder = doc.data()['qualOrderResults'] != null ? doc.data()['qualOrderResults'] : [];
                const finishPosition = doc.data()['finishPosition'] != null ? doc.data()['finishPosition'] : [];
                const fastestLap = doc.data()['fastestLap'] != null ? doc.data()['fastestLap'] : null;
                const dnfs = doc.data()['dnfsResults'] != null ? doc.data()['dnfsResults'] : [];
                const sprint_q = doc.data()['sprint_q_results'] != null ? doc.data()['sprint_q_results'] : [];

                this.state.data['qualifying'].columns.q1.driverIds = q1;
                this.state.data['qualifying'].columns.q2.driverIds = q2;
                this.state.data['qualifying'].columns.q3.driverIds = q3;
                this.state.data['qualOrder'].columns.qualOrder.driverIds = qualOrder;
                this.state.data['race'].columns.finishPosition.driverIds = finishPosition;
                this.state.fastestLap = fastestLap;
                this.state.data['dnfs'].columns.dnfs.driverIds = dnfs;
                this.state.data['sprint_q'].columns.sprint_q.driverIds = sprint_q;

                this.filterAndCompare({ q1, q2, q3, qualOrder, finishPosition, dnfs, fastestLap, sprint_q });
            }
            this.forceUpdate();
            this.setState({
                ...this.state,
                loaded: true
            })
        });
    }

    async getPredictions(isPredicting) {
        if (!this.props.readOnly) {
            let id = firebase.auth().currentUser.uid;
            let ref = db.collection("races").doc(this.state.race.id).collection("predictions").doc(id);
            // let ref = db.collection("predictions")
            //     .where("raceID", "==", this.state.race.id)
            //     .where("userID", "==", id);

            ref.get().then((doc) => { // R E A L T I M E  U P D A T I N G
                if (doc.exists) {
                    const q1 = doc.data()['q1'] != null ? doc.data()['q1'] : [];
                    const q2 = doc.data()['q2'] != null ? doc.data()['q2'] : [];
                    const q3 = doc.data()['q3'] != null ? doc.data()['q3'] : [];
                    const qualOrder = doc.data()['qualOrder'] != null ? doc.data()['qualOrder'] : [];
                    const finishPosition = doc.data()['finishPosition'] != null ? doc.data()['finishPosition'] : [];
                    const fastestLap = doc.data()['fastestLap'] != null ? doc.data()['fastestLap'] : null;
                    const dnfs = doc.data()['dnfs'] != null ? doc.data()['dnfs'] : [];
                    const sprint_q = doc.data()['sprint_q'] != null ? doc.data()['sprint_q'] : [];

                    this.state.data['qualifying'].columns.q1.driverIds = q1;
                    this.state.data['qualifying'].columns.q2.driverIds = q2;
                    this.state.data['qualifying'].columns.q3.driverIds = q3;
                    this.state.data['qualOrder'].columns.qualOrder.driverIds = qualOrder;
                    this.state.data['race'].columns.finishPosition.driverIds = finishPosition;
                    this.state.fastestLap = fastestLap;
                    this.state.data['dnfs'].columns.dnfs.driverIds = dnfs;
                    this.state.data['sprint_q'].columns.sprint_q.driverIds = sprint_q;

                    this.filterAndCompare({ q1, q2, q3, qualOrder, finishPosition, dnfs, fastestLap, sprint_q });
                }
                this.forceUpdate();
                this.setState({
                    ...this.state,
                    loaded: true
                })
            });
        } else {
            if (this.props.user === "" || this.props.user == null
                || this.state.race.id === "" || this.state.race.id == null) {

                return;
            }

            const ref = db.collection("races").doc(this.state.race.id).collection("predictions").doc(this.props.user);
            // const ref = db.collection("predictions")
            //     .where("raceID", "==", this.state.race.id)
            //     .where("userID", "==", this.props.user);

            ref.get().then((doc) => {
                // loaded = true
                if (doc.exists) {
                    const q1 = doc.data()['q1'] != null ? doc.data()['q1'] : [];
                    const q2 = doc.data()['q2'] != null ? doc.data()['q2'] : [];
                    const q3 = doc.data()['q3'] != null ? doc.data()['q3'] : [];
                    const qualOrder = doc.data()['qualOrder'] != null ? doc.data()['qualOrder'] : [];
                    const finishPosition = doc.data()['finishPosition'] != null ? doc.data()['finishPosition'] : [];
                    const fastestLap = doc.data()['fastestLap'];
                    const dnfs = doc.data()['dnfs'] != null ? doc.data()['dnfs'] : [];
                    const sprint_q = doc.data()['sprint_q'] != null ? doc.data()['sprint_q'] : [];

                    this.state.data['all'].qualifying.columns.q1.driverIds = q1;
                    this.state.data['all'].qualifying.columns.q2.driverIds = q2;
                    this.state.data['all'].qualifying.columns.q3.driverIds = q3;
                    this.state.data['all'].qualOrder.columns.qualOrder.driverIds = qualOrder;
                    this.state.data['all'].race.columns.finishPosition.driverIds = finishPosition;
                    this.state.data['all'].sprint_q.columns.sprint_q.driverIds = sprint_q;
                    this.setState({
                        ...this.state,
                        fastestLap: fastestLap
                    });

                    this.state.data['all'].dnfs.columns.dnfs.driverIds = dnfs;

                    this.filterAndCompare({ q1, q2, q3, qualOrder, finishPosition, dnfs, fastestLap, sprint_q });
                }
                this.forceUpdate();
                curr = this.props.user;
                this.setState({ ...this.state, gotPredictions: false, loaded: true })
            });
        }
    }

    async sendResultsAndScores(which, data) {
        let document = db.collection("races").doc(this.state.race.id)
        let allPredictions = {};
        await document.collection('predictions').get().then((querySnapshot) => {
            // all user predictions for this race
            querySnapshot.forEach((doc) => {
                allPredictions[doc.id] = doc.data();
            })
        })
        const numPreds = Object.keys(allPredictions).length;
        switch (which) {
            case QUALIFYING:
                document.set({
                    q1: data.q1.driverIds,
                    q2: data.q2.driverIds,
                    q3: data.q3.driverIds
                }, { merge: true }).then(n => {
                    // compute and store scores for all users with predictions
                    let results = {
                        q1: new Set(data.q1.driverIds),
                        q2: new Set(data.q2.driverIds),
                        q3: new Set(data.q3.driverIds)
                    };
                    let i = 0;
                    for (const [id, bigPred] of Object.entries(allPredictions)) {
                        // loop users
                        if (bigPred.name != null) {
                            delete bigPred.name;
                        }
                        let qualScore = 0;
                        let pred = {
                            q1: bigPred.q1 != null ? bigPred.q1 : [],
                            q2: bigPred.q2 != null ? bigPred.q2 : [],
                            q3: bigPred.q3 != null ? bigPred.q3 : []
                        }
                        for (const [stage, predDrivers] of Object.entries(pred)) {
                            predDrivers.forEach((elem, idx) => {
                                if (results[stage].has(elem)) {
                                    qualScore++;
                                }
                            })
                        }
                        let ref = db.collection('users')
                            .doc(id)
                            .collection('scores')
                            .doc(this.props.year.toString())

                        ref.update({
                            [`${this.state.race.id}.qualScore`]: qualScore
                        }).then(() => {
                            i = this.scoresAreComputed(i, numPreds);
                        })
                            .catch((error) => {
                                if (error.message.includes("No document to update")) {
                                    // problem is score doc doesn't exist, create it
                                    ref.set({
                                        [this.state.race.id]: {
                                            qualScore: qualScore
                                        }
                                    }, { merge: true }).then(() => {
                                        i = this.scoresAreComputed(i, numPreds);
                                    })
                                } else {
                                    // actual failure :(
                                    console.log(error)
                                    this.setState({
                                        ...this.state,
                                        submit: <HighlightOffIcon style={{ fontSize: 40, color: "#ff0000" }} />
                                    })
                                }
                            })
                    }
                }).catch((error) => {
                    console.log(error)
                    this.setState({
                        ...this.state,
                        submit: <HighlightOffIcon style={{ fontSize: 40, color: "#ff0000" }} />
                    })
                });
                break;
            case QUALORDER:
                document.set({
                    qualOrderResults: data.qualOrder.driverIds
                }, { merge: true }).then(n => {
                    // compute and store scores for all users with predictions
                    let results = data.qualOrder.driverIds
                    let i = 0;
                    const qScores = _.range(10, 0);
                    for (const [id, bigPred] of Object.entries(allPredictions)) {
                        if (bigPred.name != null) {
                            delete bigPred.name;
                        }
                        let pred = bigPred.qualOrder != null ? bigPred.qualOrder : [];
                        let score = 0;
                        pred.forEach((elem, idx) => {
                            if (idx >= pred.length) {
                                return
                            }
                            if (results[idx] === elem) {
                                score += qScores[idx]
                            }
                        })
                        let ref = db.collection('users')
                            .doc(id)
                            .collection('scores')
                            .doc(this.props.year.toString())

                        ref.update({
                            [`${this.state.race.id}.qualOrderScore`]: score
                        }).then(() => {
                            i = this.scoresAreComputed(i, numPreds);
                        })
                            .catch((error) => {
                                if (error.message.includes("No document to update")) {
                                    // problem is score doc doesn't exist, create it
                                    ref.set({
                                        [this.state.race.id]: {
                                            qualOrderScore: score
                                        }
                                    }, { merge: true }).then(() => {
                                        i = this.scoresAreComputed(i, numPreds);
                                    })
                                } else {
                                    // actual failure :(
                                    console.log(error)
                                    this.setState({
                                        ...this.state,
                                        submit: <HighlightOffIcon style={{ fontSize: 40, color: "#ff0000" }} />
                                    })
                                }
                            })
                    }
                }).catch((error) => {
                    console.log(error)
                    this.setState({
                        ...this.state,
                        submit: <HighlightOffIcon style={{ fontSize: 40, color: "#ff0000" }} />
                    })
                });
                break;
            case RACE:
                document.set({
                    finishPosition: data.finishPosition.driverIds,
                    fastestLap: this.state.fastestLap
                }, { merge: true }).then(n => {
                    // compute and store scores for all users with predictions
                    let results = data.finishPosition.driverIds
                    let fastestLap = this.state.fastestLap
                    let i = 0;
                    const rScores = [25, 18, 15, 12, 10, 8, 6, 4, 2].concat(Array(11).fill(1));
                    for (const [id, bigPred] of Object.entries(allPredictions)) {
                        if (bigPred.name != null) {
                            delete bigPred.name;
                        }
                        let pred = bigPred.finishPosition != null ? bigPred.finishPosition : [];
                        let score = 0;
                        pred.forEach((elem, idx) => {
                            if (idx >= pred.length) {
                                return
                            }
                            if (results[idx] === elem) {
                                score += rScores[idx]
                            }
                        })

                        for (const [, value] of Object.entries(teams[this.props.year.toString()])) {
                            if (results.indexOf(value[0]) > results.indexOf(value[1])
                                && pred.indexOf(value[0]) > pred.indexOf(value[1])) {
                                score += 3
                            } else if (results.indexOf(value[0]) < results.indexOf(value[1])
                                && pred.indexOf(value[0]) < pred.indexOf(value[1])) {
                                score += 3
                            }
                        }
                        let lapScore = 0
                        const fastPred = bigPred.fastestLap != null ? bigPred.fastestLap : "";
                        if (fastPred === fastestLap) {
                            lapScore = 5
                        }

                        let ref = db.collection('users')
                            .doc(id)
                            .collection('scores')
                            .doc(this.props.year.toString())

                        ref.update({
                            [`${this.state.race.id}.finishScore`]: score,
                            [`${this.state.race.id}.lapScore`]: lapScore
                        }).then(() => {
                            i = this.scoresAreComputed(i, numPreds);
                        })
                            .catch((error) => {
                                if (error.message.includes("No document to update")) {
                                    // problem is score doc doesn't exist, create it
                                    ref.set({
                                        [this.state.race.id]: {
                                            finishScore: score,
                                            lapScore: lapScore
                                        }
                                    }, { merge: true }).then(() => {
                                        i = this.scoresAreComputed(i, numPreds);
                                    })
                                } else {
                                    // actual failure :(
                                    console.log(error)
                                    this.setState({
                                        ...this.state,
                                        submit: <HighlightOffIcon style={{ fontSize: 40, color: "#ff0000" }} />
                                    })
                                }
                            })
                    }
                });
                break;
            case DNFs:
                document.set({
                    dnfsResults: data.dnfs.driverIds
                }, { merge: true }).then(n => {
                    // compute and store scores for all users with predictions
                    let results = new Set(data.dnfs.driverIds);
                    let i = 0;
                    for (const [id, bigPred] of Object.entries(allPredictions)) {
                        if (bigPred.name != null) {
                            delete bigPred.name;
                        }
                        let pred = bigPred.dnfs != null ? bigPred.dnfs : [];
                        let score = 0;
                        pred.forEach((elem, idx) => {
                            if (results.has(elem)) {
                                score += 5
                            }
                        })
                        let ref = db.collection('users')
                            .doc(id)
                            .collection('scores')
                            .doc(this.props.year.toString())

                        ref.update({
                            [`${this.state.race.id}.dnfScore`]: score
                        }).then(() => {
                            i = this.scoresAreComputed(i, numPreds);
                        })
                            .catch((error) => {
                                if (error.message.includes("No document to update")) {
                                    // problem is score doc doesn't exist, create it
                                    ref.set({
                                        [this.state.race.id]: {
                                            dnfScore: score
                                        }
                                    }, { merge: true }).then(() => {
                                        i = this.scoresAreComputed(i, numPreds);
                                    })
                                } else {
                                    // actual failure :(
                                    console.log(error)
                                    this.setState({
                                        ...this.state,
                                        submit: <HighlightOffIcon style={{ fontSize: 40, color: "#ff0000" }} />
                                    })
                                }
                            })
                    }
                }).catch((error) => {
                    console.log(error)
                    this.setState({
                        ...this.state,
                        submit: <HighlightOffIcon style={{ fontSize: 40, color: "#ff0000" }} />
                    })
                });
                break;
            case SPRINT_Q:
                document.set({
                    sprint_q_results: data.sprint_q.driverIds
                }, { merge: true }).then(n => {
                    // compute and store scores for all users with predictions
                    let results = data.sprint_q.driverIds;
                    let i = 0;
                    const rScores = [10, 9, 8, 7, 6, 5, 4, 3, 2].concat(Array(11).fill(1));
                    for (const [id, bigPred] of Object.entries(allPredictions)) {
                        if (bigPred.name != null) {
                            delete bigPred.name;
                        }
                        let pred = bigPred.sprint_q != null ? bigPred.sprint_q : [];
                        let score = 0;
                        pred.forEach((elem, idx) => {
                            if (idx >= pred.length) {
                                return
                            }
                            if (results[idx] === elem) {
                                score += rScores[idx]
                            }
                        })

                        let ref = db.collection('users')
                            .doc(id)
                            .collection('scores')
                            .doc(this.props.year.toString())

                        ref.update({
                            [`${this.state.race.id}.sprint_q_score`]: score
                        }).then(() => {
                            i = this.scoresAreComputed(i, numPreds);
                        })
                            .catch((error) => {
                                if (error.message.includes("No document to update")) {
                                    // problem is score doc doesn't exist, create it
                                    ref.set({
                                        [this.state.race.id]: {
                                            sprint_q_score: score,
                                        }
                                    }, { merge: true }).then(() => {
                                        i = this.scoresAreComputed(i, numPreds);
                                    })
                                } else {
                                    // actual failure :(
                                    console.log(error)
                                    this.setState({
                                        ...this.state,
                                        submit: <HighlightOffIcon style={{ fontSize: 40, color: "#ff0000" }} />
                                    })
                                }
                            })
                    }
                });
                break;
            default:
                break;
        }
    }

    scoresAreComputed(i, numPreds) {
        if (i === numPreds - 1) {
            // done
            this.setState({
                ...this.state,
                submit: <CheckCircleOutlineIcon style={{ fontSize: 40, color: "#3bd77b" }} />
            });
            setTimeout(() => {
                this.setState({
                    ...this.state,
                    submit: <p>Submit</p>
                });
            }, 1000);
        } else {
            i++
        }
        return i;
    }

    async sendData(which, data) {
        let id = firebase.auth().currentUser.uid;
        let document = db.collection("races").doc(this.state.race.id).collection("predictions").doc(id)
        switch (which) {
            case QUALIFYING:
                if (new Date() <= this.state.race["qual_time"].toDate()) {
                    document.set({
                        q1: data.q1.driverIds,
                        q2: data.q2.driverIds,
                        q3: data.q3.driverIds
                    }, { merge: true }).then(n => {
                        this.setState({
                            ...this.state,
                            submit: <CheckCircleOutlineIcon style={{ fontSize: 40, color: "#3bd77b" }} />
                        });
                        setTimeout(() => {
                            this.setState({
                                ...this.state,
                                submit: <p>Submit</p>
                            });
                        }, 1000);
                        this.setRaceName(id)
                    });
                } else {
                    this.setState({
                        ...this.state,
                        submit: <HighlightOffIcon style={{ fontSize: 40, color: "#ff0000" }} />
                    })
                }
                break;
            case QUALORDER:
                if (new Date() <= this.state.race["q_order_time"].toDate()) {
                    document.set({
                        qualOrder: data.qualOrder.driverIds
                    }, { merge: true }).then(n => {
                        this.setState({
                            ...this.state,
                            submit: <CheckCircleOutlineIcon style={{ fontSize: 40, color: "#3bd77b" }} />
                        });
                        setTimeout(() => {
                            this.setState({
                                ...this.state,
                                submit: <p>Submit</p>
                            });
                        }, 1000);
                        this.setRaceName(id)
                    });
                } else {
                    this.setState({
                        ...this.state,
                        submit: <HighlightOffIcon style={{ fontSize: 40, color: "#ff0000" }} />
                    })
                }
                break;
            case RACE:
                if (new Date() <= this.state.race["race_time"].toDate()) {
                    document.set({
                        finishPosition: data.finishPosition.driverIds,
                        fastestLap: this.state.fastestLap
                    }, { merge: true }).then(n => {
                        this.setState({
                            ...this.state,
                            submit: <CheckCircleOutlineIcon style={{ fontSize: 40, color: "#3bd77b" }} />
                        });
                        setTimeout(() => {
                            this.setState({
                                ...this.state,
                                submit: <p>Submit</p>
                            });
                        }, 1000);
                        this.setRaceName(id)
                    });
                } else {
                    this.setState({
                        ...this.state,
                        submit: <HighlightOffIcon style={{ fontSize: 40, color: "#ff0000" }} />
                    })
                }
                break;
            case DNFs:
                if (new Date() <= this.state.race["race_time"].toDate()) {
                    document.set({
                        dnfs: data.dnfs.driverIds
                    }, { merge: true }).then(n => {
                        this.setState({
                            ...this.state,
                            submit: <CheckCircleOutlineIcon style={{ fontSize: 40, color: "#3bd77b" }} />
                        });
                        setTimeout(() => {
                            this.setState({
                                ...this.state,
                                submit: <p>Submit</p>
                            });
                        }, 1000);
                        this.setRaceName(id)
                    });
                } else {
                    this.setState({
                        ...this.state,
                        submit: <HighlightOffIcon style={{ fontSize: 40, color: "#ff0000" }} />
                    })
                }
                break;
            case SPRINT_Q:
                if (new Date() <= this.state.race["sprint_q_time"].toDate()) {
                    document.set({
                        sprint_q: data.sprint_q.driverIds
                    }, { merge: true }).then(n => {
                        this.setState({
                            ...this.state,
                            submit: <CheckCircleOutlineIcon style={{ fontSize: 40, color: "#3bd77b" }} />
                        });
                        setTimeout(() => {
                            this.setState({
                                ...this.state,
                                submit: <p>Submit</p>
                            });
                        }, 1000);
                        this.setRaceName(id)
                    });
                } else {
                    this.setState({
                        ...this.state,
                        submit: <HighlightOffIcon style={{ fontSize: 40, color: "#ff0000" }} />
                    })
                }
                break;
            default:
                break;
        }
    };

    setRaceName(id) {
        let name = firebase.auth().currentUser.displayName
        name = name != null && name !== "" ? name : firebase.auth().currentUser.email
        db.collection("users").doc(id).set({
            name: name
        }, { merge: true });
    }

    /**
     * Filters data columns and processes results vs predictions
     * @param data user predictions
     */
    filterAndCompare(data) {
        this.state.data['qualifying'].columns.drivers1.driverIds = this.state.data['qualifying'].columns.drivers1.driverIds.filter(val =>
            !data.q1.includes(val) && !data.q2.includes(val) && !data.q3.includes(val));
        this.state.data['qualifying'].columns.drivers2.driverIds = this.state.data['qualifying'].columns.drivers2.driverIds.filter(val =>
            !data.q1.includes(val) && !data.q2.includes(val) && !data.q3.includes(val));
        this.state.data['qualOrder'].columns.drivers1.driverIds = this.state.data['qualOrder'].columns.drivers1.driverIds.filter(val =>
            !data.qualOrder.includes(val));
        this.state.data['qualOrder'].columns.drivers2.driverIds = this.state.data['qualOrder'].columns.drivers2.driverIds.filter(val =>
            !data.qualOrder.includes(val));
        this.state.data['race'].columns.drivers1.driverIds = this.state.data['race'].columns.drivers1.driverIds.filter(val =>
            !data.finishPosition.includes(val));
        this.state.data['race'].columns.drivers2.driverIds = this.state.data['race'].columns.drivers2.driverIds.filter(val =>
            !data.finishPosition.includes(val));
        this.state.data['dnfs'].columns.drivers1.driverIds = this.state.data['dnfs'].columns.drivers1.driverIds.filter(val =>
            !data.dnfs.includes(val));
        this.state.data['dnfs'].columns.drivers2.driverIds = this.state.data['dnfs'].columns.drivers2.driverIds.filter(val =>
            !data.dnfs.includes(val));

        this.state.data['sprint_q'].columns.drivers1.driverIds = this.state.data['sprint_q'].columns.drivers1.driverIds.filter(val =>
            !data.sprint_q.includes(val));
        this.state.data['sprint_q'].columns.drivers2.driverIds = this.state.data['sprint_q'].columns.drivers2.driverIds.filter(val =>
            !data.sprint_q.includes(val));

        // compare results (if known) to predictions in order to pass simple display bool arrays
        let pred = data.q1
        let results = new Set(this.state.race.q1)

        let forState = {}
        let qualScore = 0

        let q1 = []
        if (pred && results) {
            pred.forEach((elem, idx) => {
                q1.push(results.has(elem))
                if (results.has(elem)) {
                    qualScore++
                }
            })
        }
        forState.q1 = q1

        pred = data.q2
        results = new Set(this.state.race.q2)
        let q2 = []
        if (pred && results) {
            pred.forEach((elem, idx) => {
                q2.push(results.has(elem))
                if (results.has(elem)) {
                    qualScore++
                }
            })
        }
        forState.q2 = q2

        pred = data.q3
        results = new Set(this.state.race.q3)
        let q3 = []
        if (pred && results) {
            pred.forEach((elem, idx) => {
                q3.push(results.has(elem))
                if (results.has(elem)) {
                    qualScore++
                }
            })
        }
        forState.q3 = q3

        let qualOrderScore = 0
        let qScores = _.range(10, 0)

        pred = data.qualOrder
        results = this.state.race.qualOrder
        let qualOrder = []
        if (pred && results) {
            pred.forEach((elem, idx) => {
                if (idx >= pred.length) {
                    return
                }
                qualOrder.push(results[idx] === elem)
                if (results[idx] === elem) {
                    qualOrderScore += qScores[idx]
                }
            })
        }
        forState.qualOrder = qualOrder

        let finishScore = 0
        const rScores = [25, 18, 15, 12, 10, 8, 6, 4, 2].concat(Array(11).fill(1))

        pred = data.finishPosition
        results = this.state.race.finishPosition
        let finishPosition = []
        if (pred && results) {
            pred.forEach((elem, idx) => {
                if (idx >= pred.length) {
                    return
                }
                finishPosition.push(results[idx] === elem)
                if (results[idx] === elem) {
                    finishScore += rScores[idx]
                }
            })
        }
        forState.finishPosition = finishPosition

        if (pred && results) {
            for (const [, value] of Object.entries(teams[this.props.year.toString()])) {
                if (results.indexOf(value[0]) > results.indexOf(value[1])
                    && pred.indexOf(value[0]) > pred.indexOf(value[1])) {

                    finishScore += 3
                } else if (results.indexOf(value[0]) < results.indexOf(value[1])
                    && pred.indexOf(value[0]) < pred.indexOf(value[1])) {

                    finishScore += 3
                }
            }
        }

        /** BEGIN SPRINT QUALI **/
        let sprint_q_score = 0
        const sqScores = [10, 9, 8, 7, 6, 5, 4, 3, 2].concat(Array(11).fill(1))

        pred = data.sprint_q
        results = this.state.race.sprint_q
        let sprint_q = []
        if (pred && results) {
            pred.forEach((elem, idx) => {
                if (idx >= pred.length) {
                    return
                }
                sprint_q.push(results[idx] === elem)
                if (results[idx] === elem) {
                    sprint_q_score += sqScores[idx]
                }
            })
        }
        forState.sprint_q = sprint_q
        /** END SPRINT QUALI**/

        let lapScore = 0

        pred = data.fastestLap
        results = this.state.race.fastestLap
        let fastestLap = false
        if (pred && results) {
            fastestLap = pred === results
            if (fastestLap) {
                lapScore = 5
            }
        }
        forState.fastestLap = fastestLap

        let dnfScore = 0

        pred = data.dnfs
        results = new Set(Array.isArray(this.state.race.dnfs) ? this.state.race.dnfs : undefined)
        let dnfs = []
        if (pred && results) {
            pred.forEach((elem, idx) => {
                dnfs.push(results.has(elem))
                if (results.has(elem)) {
                    dnfScore += 5
                }
            })
        }
        forState.dnfs = dnfs

        this.setState({
            ...this.state,
            bools: forState,
            qualScore: qualScore,
            qualOrderScore: qualOrderScore,
            finishScore: finishScore,
            lapScore: lapScore,
            dnfScore: dnfScore,
            sprint_q_score: sprint_q_score
        })
    }
}

export default DriversPanel;