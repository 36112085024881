import React from "react";
import "./background.css"

class Background extends React.Component {
    render() {
        return (
            <div className={"background-v"}>
                <div className={"vid-bg"}>
                    <div id={"overlay"}>
                    </div>
                    <video id={"vid"} autoPlay muted loop>
                        <source src={"/assets/bg.webm"} type={"video/webm"}/>
                        <source src={"/assets/bg.mp4"} type={"video/mp4"}/>
                    </video>
                </div>
                <div className={"construction"}>
                    <h1>{this.props.isConstruction ? "under (re)construction for 2023" : ""}</h1>
                </div>
            </div>
        )
    }
}

export default Background;