import React, { useState } from "react";
import './RaceAdd.css'
import { CircularProgress, Fab, TextField, Typography } from "@material-ui/core";
import {
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import Grid from '@material-ui/core/Grid';
import moment, { Moment } from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { green, red } from '@material-ui/core/colors';
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';
import SendIcon from '@material-ui/icons/Send';
import AddIcon from '@material-ui/icons/Add';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import firebase from "firebase/app";
import "firebase/firestore";
import { firebaseConfig } from "../../../constants/values";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

!firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();

const db = firebase.firestore();

export default function RaceAdd() {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);
    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    })

    const handleResetClick = () => {
        setLoading(false);
        setSuccess(false);
        setFailed(false);
        handleNameChange("")
        handleRTimeChange(moment())
        handleQTimeChange(qual)
        handleQOTimeChange(moment(qual).add(45, 'minutes'))
        setRaceId("");
    }

    const [raceId, setRaceId] = useState("");

    let qual = moment();
    const [name, handleNameChange] = useState("");
    const [race_time, handleRTimeChange] = useState(moment());
    const [sprint_time, handleSprintChange] = useState(moment());
    const [qual_time, handleQTimeChange] = useState(qual);
    const [qual_order_time, handleQOTimeChange] = useState(moment(qual).add(45, 'minutes'));
    const [hasSprint, setHasSprint] = useState(false);

    const handleHasSprint = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHasSprint(event.target.checked);
    }

    const handleSubmitClick = () => {
        if (!loading) {
            setSuccess(false);
            setLoading(true);
            submitRace(name, race_time, qual_time, qual_order_time, raceId, hasSprint, sprint_time).then((res) => {
                if (res[0]) {
                    setSuccess(true);
                    setLoading(false);
                    setFailed(false);
                    setRaceId(res[1])
                } else {
                    setFailed(true);
                    setSuccess(false);
                    setLoading(false);
                    setRaceId(res[1] !== "" ? res[1] : "")
                }
            });
        }
    }


    return (
        <div className={'race-add'}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Typography variant={'h5'}>Add New Race</Typography><br />
                <Grid container justify={"space-around"} id={"race-add-form"}>
                    <Grid item xs={12}>
                        <TextField id={'race-name'} label={"Race Name"} value={name} margin={"normal"}
                            onChange={(e) => handleNameChange(e.target.value)} />
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={hasSprint} onChange={handleHasSprint} color="primary" />} label="Sprint Race" labelPlacement="top" />
                        </FormGroup>
                    </Grid>
                    {hasSprint &&
                        <DateTimePicker id={'sprint-race-time'} value={sprint_time} label={"Sprint Time"} format={"MM/DD/YYYY hh:mm A"}
                            margin={"normal"} onChange={(date) => handleSprintChange(moment(date))} />
                    }
                    <DateTimePicker id={'race-time'} value={race_time} label={"Race Time"} format={"MM/DD/YYYY hh:mm A"}
                        margin={"normal"} onChange={(date) => handleRTimeChange(moment(date))} />

                    <DateTimePicker id={'qual-time'} value={qual_time} label={"Qualifying Time"}
                        format={"MM/DD/YYYY hh:mm A"}
                        margin={"normal"}
                        onChange={(date) => {
                            handleQTimeChange(moment(date));
                            handleQOTimeChange(moment(date).add(45, 'minutes'));
                        }} />

                    <DateTimePicker id={'qual-order-time'} value={qual_order_time} label={"Qualifying" +
                        " Order Time"} format={"MM/DD/YYYY hh:mm A"}
                        margin={"normal"}
                        helperText={"Default: Quali + 45 min"}
                        onChange={(date) => handleQOTimeChange(moment(date))} />
                    <div className={classes.root}>
                        <div className={classes.wrapper}>
                            <Fab
                                aria-label={"save"}
                                color={failed ? "secondary" : "primary"}
                                className={buttonClassname}
                                onClick={handleSubmitClick}>
                                {success ? <CheckIcon /> : failed ? <ErrorOutlineIcon /> : <SendIcon />}
                            </Fab>
                            {success ?
                                <Fab
                                    aria-label={"add"}
                                    color={"primary"}
                                    size={"small"}
                                    style={{ marginLeft: "5px", position: "fixed" }}
                                    onClick={handleResetClick}>
                                    <AddIcon />
                                </Fab> : null}
                            {loading && <CircularProgress size={68} className={classes.fabProgress} />}
                        </div>
                    </div>
                </Grid>
                {failed ? <Typography variant={"subtitle1"} style={{ color: red[500] }}>Error: add a race name</Typography> : null}
            </MuiPickersUtilsProvider>
        </div>
    )
}

async function submitRace(name: string, race_time: Moment, qual_time: Moment, qual_order_time: Moment, id: string, hasSprint: boolean, sprint_time: Moment): Promise<[boolean, string]> {
    let newRef = id !== "" ? db.collection("races").doc(id) : db.collection("races").doc();

    if (name === "") {
        return [false, newRef.id];
    }

    let success = false;
    await newRef.set({
        name: name,
        race_time: firebase.firestore.Timestamp.fromDate(race_time.toDate()),
        qual_time: firebase.firestore.Timestamp.fromDate(qual_time.toDate()),
        q_order_time: firebase.firestore.Timestamp.fromDate(qual_order_time.toDate()),
        sprint_q_time: firebase.firestore.Timestamp.fromDate(sprint_time.toDate()),
        hasSprint: hasSprint,
        year: 2023
    }, { merge: true }).then(() => {
        success = true;
    }).catch((error) => {
        console.log(error)
        success = false;
    })

    return [success, newRef.id]
}